import InputLabel from "../UI/Inputs/InputLabel";
import InputText from "../UI/Inputs/InputText";
import BasicButton from "../UI/Buttons/BasicButton";

const LoginCredentials = (props) => {
    return (
        <form className="space-y-6" onSubmit={props.onLogin}>
            <div>
                <InputLabel htmlFor="email" title={'Email address'}/>
                <InputText name="email" type="email" required={true} placeholder="user@example.org" value={props.email}
                           onChange={(e) => props.setEmail(e.target.value)}/>
            </div>

            <div>
                <InputLabel htmlFor="password" title={'Password'}/>
                <InputText name="password" type="password" required={true} placeholder="*********"
                           value={props.password} onChange={(e) => props.setPassword(e.target.value)}/>
            </div>

            <div className="flex items-center justify-between">
                <div className="flex items-center">
                </div>

                <div className="text-sm">
                    <span className="font-medium text-indigo-600 hover:text-indigo-500">
                        Forgot your password?
                    </span>
                </div>
            </div>

            <div>
                <BasicButton type={'primary'} title={'Sign in'} buttonType={'submit'} className={'w-full justify-center'}/>
            </div>
        </form>
    )
}

export default LoginCredentials;
