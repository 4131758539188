import {DocumentIcon} from "@heroicons/react/outline";
import Card from "../../Card/Card";

const EmptyMetadataState = (props) => {
    return (
        <Card className={props.className}>
            <div className="h-full flex flex-col items-center justify-center pt-10 pb-10 md:pl-0 md:pr-0 pl-3 pr-3">
                <DocumentIcon className="h-12 w-12 text-gray-400"/>
                <h3 className="mt-2 text-md font-medium text-gray-900">
                    No metadata found
                </h3>
                <p className="mt-1 text-sm text-gray-500 text-center">
                    Perimètre hasn't found any metadata for the service yet. Come back for more!
                </p>
            </div>
        </Card>
    )
}

export default EmptyMetadataState;