const CardHeader = (props) => {
    return (
        <div className="px-4 py-5 sm:px-6 flex justify-between">
            <span>
                {props.title}
            </span>
            {props.children}
        </div>
    )
}

export default CardHeader;