import BasicButton from "../UI/Buttons/BasicButton";
import InputCheckbox from "../UI/Inputs/InputCheckbox";
import InputSelect from "../UI/Inputs/InputSelect";
import InputSelectOption from "../UI/Inputs/InputSelectOption";

const notificationOptions = [
    'asset',
    'threat',
    'other'
];

const MemberTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                        <img className="h-10 w-10 rounded-full"
                             src={props.avatar ?? 'https://avatars.githubusercontent.com/u/10137'}
                             alt=""/>
                    </div>
                    <div className="ml-4">
                        <div className="font-medium text-gray-900">{props.name}</div>
                        <div className="text-gray-500">{props.email}</div>
                    </div>
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                {props.level}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {
                    props.level === 'team' || props.email === props.currentUser ?
                        <span className={'capitalize'}>
                            {props.role}
                        </span> :
                        <InputSelect onChange={(e) => props.onChangeMemberRole(props.email, e.target.value)} defaultValue={props.role}>
                            {props.availableRoles.map((role) => <InputSelectOption key={role} id={role} value={role.toLowerCase()} title={role[0].toUpperCase() + role.slice(1)}/>)}
                        </InputSelect>
                }
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex flex-col gap-2">
                {notificationOptions.map((optionName) => {
                    return (
                        <div key={optionName} className={'flex items-center gap-2.5'}>
                            <InputCheckbox defaultChecked={props.notifications?.[optionName] ?? false}
                                           onChange={(e) => props.onChangeMemberNotificationOption(props.email, optionName, e.target.checked)}
                                           disabled={props.level === 'team'} />
                            <span className={'capitalize'}>{optionName+'s'}</span>
                        </div>
                    )
                })}
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                {
                    props.level === 'workspace' && props.email !== props.currentUser ?
                        <BasicButton onClick={() => props.onRemoveMember(props.email)} title={'Remove'} type={'actionDelete'}/>
                         : null
                }
            </td>
        </tr>
    )
}

export default MemberTableItem;
