import MemberTableItem from "./MemberTableItem";
import Table from "../Table/Table";
import {PlusIcon} from "@heroicons/react/solid";
import React from "react";
import BasicButton from "../UI/Buttons/BasicButton";

const MemberTable = (props) => {
    const members = (props.members ?? []).map((member) => {
        return {
            tableKey: member.email,
            ...member
        };
    });

    const TableItem = (member) => {
        return <MemberTableItem key={member.email} {...member}
                                onChangeMemberRole={props.onChangeMemberRole}
                                onChangeMemberNotificationOption={props.onChangeMemberNotificationOption}
                                onRemoveMember={props.onRemoveMember}
                                currentUser={props.currentUser}/>;
    }

    const TableActions = () => {
        return (
            <>
                <BasicButton title={'Invite member'} onClick={() => props.onInviteMember()}
                             icon={<PlusIcon className="h-5 w-5 text-white" aria-hidden="true"/>} type={'primary'}/>
            </>
        )
    }

    return (
        <Table className={props.className}
               title={'Members'}
               keys={['Name', 'Source', 'Role', 'Notifications', '']}
               items={members}
               tableItem={TableItem}
               action={TableActions}/>
    )
}

export default MemberTable;
