import Card from "../Card/Card";
import EmptyTableState from "./EmptyTableState";
import TableCardHeader from "./TableCardHeader";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";
import React, {useEffect, useState} from "react";
import TablePagination from "./TablePagination";

function paginate(array, page, size) {
    if (size === -1) {
        return array;
    }

    const startIndex = page * size;
    const endIndex = startIndex + size;
    return array.slice(startIndex, endIndex);
}

const Table = (props) => {
    const TableItem = props.tableItem;
    const EmptyState = props.emptyState ?? EmptyTableState;
    const TableAction = props.action;

    const [paginationPage, setPaginationPage] = useState(0);
    const [paginationSize, setPaginationSize] = useState(-1);

    useEffect(() => {
        setPaginationSize(props.paginationSize !== undefined ? props.paginationSize : -1);
    }, [props.paginationSize]);

    const onPreviousPage = () => {
        if (paginationPage > 0) {
            setPaginationPage(paginationPage -1);
        }
    }

    const onNextPage = () => {
        if ((paginationPage + 1) * paginationSize < props.items.length) {
            setPaginationPage(paginationPage + 1);
        }
    }

    const onClickTableHeader = (headerKey) => {
        if (props.onClickTableHeader) {
            props.onClickTableHeader(headerKey);
        }
    };

    return (
        <Card className={props.className}>
            {props.title !== undefined ? (
                <TableCardHeader title={props.title ?? ''} count={props.count ?? props.items.length}>
                    {TableAction !== undefined ? <TableAction/> : null}
                </TableCardHeader>
            ) : null}
            <div className="shadow-sm overflow-x-auto h-full rounded-b-lg">
                {
                    props.items.length === 0 ? <EmptyState/> :
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                            <tr>
                                {
                                    props.keys.map((key) =>
                                        <th key={key} scope="col" onClick={() => onClickTableHeader(key)}
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize cursor-pointer">
                                            {key}
                                            {props.sortField === key ? (
                                                props.sortOrder === 'asc' ?
                                                    <ChevronUpIcon className="-mr-1 ml-2 h-5 w-5 inline-block"
                                                                     aria-hidden="true"/> :
                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 inline-block"
                                                                     aria-hidden="true"/>
                                            ) : null}
                                        </th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                            {
                                paginate(props.items.sort((a, b) => b.id - a.id), paginationPage, paginationSize)
                                    .map((item) => <TableItem key={item.tableKey} {...item}/>)
                            }
                            </tbody>
                        </table>
                }
            </div>
            {props.usePagination && props.items.length > 0 ? (
                <TablePagination paginationPage={paginationPage}
                                 paginationSize={paginationSize}
                                 paginationTotal={props.items.length}
                                 onPreviousPage={onPreviousPage}
                                 onNextPage={onNextPage}/>
            ) : null}
        </Card>
    )
}

export default Table;
