import React, {Fragment} from 'react';
import {Menu, Transition} from "@headlessui/react";

function DropdownItems(props) {
    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
                className={"origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none " + (props.className ?? '')}>
                    {props.children}
            </Menu.Items>
        </Transition>
    );
}

export default DropdownItems;
