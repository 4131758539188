import {PlusIcon} from "@heroicons/react/solid";
import {FolderAddIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import BasicButton from "../UI/Buttons/BasicButton";

const EmptyWorkspaceState = () => {
    return (
        <div className="text-center">
            <FolderAddIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true"/>
            <h3 className="mt-2 text-md font-medium text-gray-900">No workspaces</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by creating a new workspace.</p>
            <div className="mt-6">
                <Link to="/workspaces/create">
                    <BasicButton title={'New Workspace'} icon={ <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>} />
                </Link>
            </div>
        </div>
    )
}

export default EmptyWorkspaceState;
