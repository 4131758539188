import {ArrowSmDownIcon, ArrowSmRightIcon, ArrowSmUpIcon} from "@heroicons/react/solid";

const colors = {
    'info': 'bg-blue-100 text-blue-800',
    'positive': 'bg-green-100 text-green-800',
    'negative': 'bg-red-100 text-red-800',
};

const icons = {
    'increase': ArrowSmUpIcon,
    'decrease': ArrowSmDownIcon,
    'neutral': ArrowSmRightIcon,
};

const statisticConfigs = {
    'percentage_active_assets': {
        'name': 'Active Assets',
        'increase': 'info',
        'decrease': 'info',
        'neutral': 'info',
        'unit': '%',
    },
    'percentage_active_secure_assets': {
        'name': 'Secure Assets',
        'increase': 'positive',
        'decrease': 'negative',
        'neutral': 'info',
        'unit': '%',
    },
    'percentage_secure_assets': {
        'name': 'Secure Assets',
        'increase': 'positive',
        'decrease': 'negative',
        'neutral': 'info',
        'unit': '%',
    },
    'percentage_threaten_assets': {
        'name': 'Threaten Assets',
        'increase': 'negative',
        'decrease': 'positive',
        'neutral': 'info',
        'unit': '%',
    },
    'total_active_assets': {
        'name': 'Active Assets',
        'increase': 'info',
        'decrease': 'info',
        'neutral': 'info',
        'unit': '',
    },
    'total_active_secure_assets': {
        'name': 'Secure Assets',
        'increase': 'positive',
        'decrease': 'negative',
        'unit': '',
    },
    'total_assets': {
        'name': 'Total Assets',
        'increase': 'info',
        'decrease': 'info',
        'neutral': 'info',
        'unit': '',
    },
    'total_secure_assets': {
        'name': 'Secure Assets',
        'increase': 'info',
        'decrease': 'info',
        'neutral': 'info',
        'unit': '',
    },
    'total_threats': {
        'name': 'Total Threats',
        'increase': 'negative',
        'decrease': 'positive',
        'neutral': 'info',
        'unit': '',
    }
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function getChange(previous, current) {
    const change = current - previous;
    return (change / previous) * 100;
}

const WorkspaceStatistic = (props) => {
    let change = getChange(props.previous_value ?? 0, props.value);
    change = change === Infinity ? 100 : change;

    const statistic = {
        ...statisticConfigs[props.statKey],
        change: Math.round(Math.abs(change) * 10) / 10 + '%',
        changeType: change === 0 ? 'neutral' : change > 0 ? 'increase' : 'decrease',
        previous_value: props.previous_value ?? 0,
        value: props.value,
    }

    const Icon = icons[statistic.changeType];

    return (
        <div key={statistic.name} className={"bg-white rounded-lg shadow px-4 py-5 sm:p-6 " + (props.className ?? '')}>
            <dt className="text-base font-normal text-gray-900">{statistic.name}</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-primary">
                    {statistic.value.toLocaleString() + statistic.unit}
                    <span className="ml-2 text-sm font-medium text-gray-500">
                        from {statistic.previous_value.toLocaleString() + statistic.unit}
                    </span>
                </div>

                <div className={classNames(
                        colors[statistic[statistic.changeType]],
                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
                    )}>
                    {<Icon className='-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5' aria-hidden='true'/> }
                    <span className="sr-only">{statistic.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span> {statistic.change}
                </div>
            </dd>
        </div>
    )
}

export default WorkspaceStatistic;
