import {FilterIcon} from "@heroicons/react/outline";
import React from "react";
import Switch from "../../Switch";
import Dropdown from "../../UI/Dropdown/Dropdown";
import DropdownButton from "../../UI/Dropdown/DropdownButton";
import DropdownItems from "../../UI/Dropdown/DropdownItems";
import DropdownItem from "../../UI/Dropdown/DropdownItem";

const AssetFilter = (props) => {

    const toggleAssetFilter = (filterKey, isEnabled) => {
        props.setAssetFilters({
            ...props.assetFilters,
            [props.parentFilterKey]: {
                ...props.assetFilters[props.parentFilterKey],
                [filterKey]: {
                    ...props.assetFilters[props.parentFilterKey][filterKey],
                    isEnabled: isEnabled,
                }
            }
        })
    }

    return (
        <Dropdown>
            <DropdownButton title={props.filterName} icon={<FilterIcon className="h-5 w-5" aria-hidden="true"/>}/>
            <DropdownItems>
                <div className={'py-1'}>
                    {Object.entries(props.assetFilters[props.parentFilterKey] ?? {}).map(([filterKey, filterConfig]) => {
                        return (
                            <DropdownItem key={filterKey} id={filterKey}>
                                <Switch label={filterConfig['label']}
                                        checked={filterConfig['isEnabled']}
                                        setChecked={() => toggleAssetFilter(filterKey, !filterConfig['isEnabled'])}/>
                            </DropdownItem>
                        )
                    })}
                </div>
            </DropdownItems>
        </Dropdown>
    )
}

export default AssetFilter;
