import {SearchIcon} from "@heroicons/react/solid";
import {Disclosure} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import NavbarTeamSelect from "./NavbarTeamSelect";
import AppContext from "../../store/app-context";
import BasicButton from "../UI/Buttons/BasicButton";
import Dropdown from "../UI/Dropdown/Dropdown";
import DropdownItemText from "../UI/Dropdown/DropdownItemText";
import DropdownItems from "../UI/Dropdown/DropdownItems";
import DropdownItem from "../UI/Dropdown/DropdownItem";
import DropdownButtonImage from "../UI/Dropdown/DropdownButtonImage";

const Navbar = (props) => {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();

    const setSelectedTeam = (team) => {
        appContext.setCurrentTeam(team);
        navigate('/');
    };

    return (
        <Disclosure as="nav" className="bg-white shadow-md">
            {({open}) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="flex px-2 lg:px-0">
                                    <div className="flex-shrink-0 flex items-center">
                                        <Link to={'/'}>
                                            <img className="block lg:hidden h-8 w-auto" src="/logo.svg"
                                                 alt="Perimetre"/>
                                            <img className="hidden lg:block h-8 w-auto" src="/logo.svg"
                                                 alt="Perimetre"/>
                                        </Link>
                                    </div>
                                </div>
                                {
                                    appContext.teams.length > 1 ? (
                                        <div
                                            className="hidden lg:flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
                                            <NavbarTeamSelect teams={appContext.teams} selected={appContext.currentTeam}
                                                              setSelected={(team) => setSelectedTeam(team)}/>
                                        </div>
                                    ) : null
                                }
                            </div>
                            {window.REACT_APP_ENV !== 'prod' ? (
                                <div className="flex items-center">
                                    <span
                                        className={'uppercase text-red-600 font-bold underline'}>{window.REACT_APP_ENV} environment</span>
                                </div>
                            ) : null}
                            <div className="flex">
                                <div className="flex items-center lg:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button
                                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="hidden lg:ml-4 lg:flex lg:items-center">
                                    {
                                        appContext.user.features.includes('GLOBAL_SEARCH') ?
                                            <BasicButton icon={<SearchIcon className="h-6 w-6 mr-3" aria-hidden="true"/>}
                                                         type={'noBorder'}
                                                         onClick={() => props.setCommandOpen(true)}/>
                                            : null
                                    }

                                    {/* Profile dropdown */}
                                    <Dropdown className={'z-10'}>
                                        <DropdownButtonImage image={appContext.user.avatar ?? 'https://avatars.githubusercontent.com/u/10137'} srOnly={'Open user menu'}/>
                                        <DropdownItems className={'divide-y-0 !w-48 py-1'}>
                                            <DropdownItem link={'/settings'}>
                                                <DropdownItemText title={'Settings'}/>
                                            </DropdownItem>
                                            <DropdownItem link={'/teams'}>
                                                <DropdownItemText title={'My teams'}/>
                                            </DropdownItem>
                                            <DropdownItem onClick={() => props.logout()} className={'cursor-pointer'}>
                                                <DropdownItemText title={'Sign out'}/>
                                            </DropdownItem>
                                        </DropdownItems>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="lg:hidden">
                        <div className="pt-4 pb-3 border-t border-gray-200">
                            <div className="flex items-center px-4">
                                <div className="flex-shrink-0">
                                    <img className="h-10 w-10 rounded-full"
                                         src={appContext.user.avatar}
                                         alt=""
                                    />
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-gray-800">
                                        {appContext.user.name}
                                    </div>
                                    <div className="text-sm font-medium text-gray-500">
                                        {appContext.user.email}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 space-y-1">
                                {
                                    appContext.teams.length > 1 ? (
                                        <div className="px-2 lg:ml-6 lg:justify-end">
                                            <NavbarTeamSelect teams={appContext.teams} selected={appContext.currentTeam}
                                                              setSelected={(team) => setSelectedTeam(team)}/>
                                        </div>
                                    ) : null
                                }
                                <Disclosure.Button
                                    as="a"
                                    href="#"
                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                                    Settings
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="a"
                                    href="#"
                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
                                    My teams
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="a"
                                    href="#"
                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                    onClick={() => props.logout()}>
                                    Sign out
                                </Disclosure.Button>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default Navbar;
