import {filterAssets} from "../AssetFilter/asset-filter";

export const buildAssetMap = (assetMap, assetMapFilters) => {
    const assets = filterAssets(assetMap.assets, assetMapFilters);
    const assetIds = assets.reduce((prev, cur) => {
        prev[cur.id] = 1;
        return prev;
    }, {});

    const links = filterLinks(assetMap.links, assetIds);

    return {
        nodes: assets,
        links: links.map((link) => {
            return {
                ...link,
                value: 1,
            }
        }),
    }
}

const filterLinks = (links, assetIds) => {
    return links.filter((link) => link.source.split('/')[1] in assetIds && link.target.split('/')[1] in assetIds);
}
