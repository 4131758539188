import {RefreshIcon} from "@heroicons/react/outline";

const LoadingAssetState = () => {
    return (
        <div className="h-full flex flex-col items-center justify-center pt-10 pb-10 md:pl-0 md:pr-0 pl-3 pr-3">
            <RefreshIcon className="animate-reverse-spin h-12 w-12 text-gray-400"/>
            <h3 className="mt-2 text-md font-medium text-gray-900">
                Loading assets
            </h3>
            <p className="mt-1 text-sm text-gray-500 text-center">
                Perimètre is loading your assets.
            </p>
        </div>
    )
}

export default LoadingAssetState;