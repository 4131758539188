import React, {useCallback, useEffect, useState} from 'react';
import {acceptInvitation} from "../requests";

const AppContext = React.createContext({
    teams: [],
    currentTeam: {},
    setCurrentTeam: (team) => {
    },
    user: {},
    token: '',
    login: (token) => {
    },
    logout: () => {
    },
    preferences: {},
    updatePreferences: (preferences) => {
    },
    invitation: null,
    setInvitation: (invitation) => {
    },
    isInitialized: false,
});

export const AppContextProvider = (props) => {
    const [teams, setTeams] = useState([]);
    const [currentTeam, setCurrentTeam] = useState({id: '', name: ''});
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [preferences, setPreferences] = useState({});
    const [invitation, setInvitation] = useState(null);
    // TODO: find a better way
    const [isInitialized, setIsInitialized] = useState(false);

    const login = useCallback((rawToken) => {
        setToken(rawToken);

        const token = rawToken.split('.')[1];
        const padding = '='.repeat((4 - token.length % 4) % 4);
        const base64 = (token + padding).replace(/-/g, '+').replace(/_/g, '/');

        const user = JSON.parse(window.atob(base64));
        setUser(user);

        const teams = user.teams ?? [];
        setTeams(teams);
        setCurrentTeam(teams[0]);

        sessionStorage.setItem('token', rawToken);

        const rawInvite = sessionStorage.getItem('invitation');
        if (rawInvite !== null) {
            const invite = JSON.parse(rawInvite);
            setInvitation(invite);

            acceptInvitation(rawToken, invite.team, invite.hash)
                .then(() => updateInvitation(null));
        }
    }, []);

    const logout = () => {
        setToken(null);
        setUser(null);
        setTeams([]);
        setCurrentTeam({id: '', name: ''});
        setInvitation(null);

        sessionStorage.removeItem('token');
    }

    const updatePreferences = (preferences) => {
        sessionStorage.setItem('preferences', JSON.stringify(preferences));
        setPreferences(preferences);
    }

    const updateInvitation = (invitation) => {
        if (invitation !== null) {
            sessionStorage.setItem('invitation', JSON.stringify(invitation));
        } else {
            sessionStorage.removeItem('invitation');
        }

        setInvitation(invitation);
    }

    useEffect(() => {
        const rawToken = sessionStorage.getItem('token');
        if (rawToken !== null) {
            login(rawToken);
        }

        const rawPreferences = sessionStorage.getItem('preferences') ?? '{}';
        setPreferences(JSON.parse(rawPreferences));

        setIsInitialized(true);
    }, [login]);

    return <AppContext.Provider
        value={{
            teams: teams,
            currentTeam: currentTeam,
            setCurrentTeam: setCurrentTeam,
            user: user,
            token: token,
            invitation: invitation,
            setInvitation: updateInvitation,
            login: login,
            logout: logout,
            preferences: preferences,
            updatePreferences: updatePreferences,
            isInitialized: isInitialized,
        }}>
        {props.children}
    </AppContext.Provider>
};

export default AppContext;