import {EyeIcon} from "@heroicons/react/outline";
import {MapIcon, TemplateIcon} from "@heroicons/react/solid";
import React from "react";
import Dropdown from "../../UI/Dropdown/Dropdown";
import DropdownButton from "../../UI/Dropdown/DropdownButton";
import DropdownItems from "../../UI/Dropdown/DropdownItems";
import DropdownItem from "../../UI/Dropdown/DropdownItem";
import DropdownItemText from "../../UI/Dropdown/DropdownItemText";

const WorkspaceMenuView = (props) => {
    return (
        <Dropdown className={'mr-2'}>
            <DropdownButton title={'View'} icon={<EyeIcon className="h-5 w-5" aria-hidden="true"/>}/>
            <DropdownItems>
                <div className={'py-1'}>
                    <DropdownItem onClick={() => props.switchViewType('card')} className={'cursor-pointer'}>
                        <DropdownItemText title={'Cards'} icon={<TemplateIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"/>}/>
                    </DropdownItem>
                    <DropdownItem onClick={() => props.switchViewType('map')} className={'cursor-pointer'}>
                        <DropdownItemText title={'2D Map'} icon={<MapIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"/>}/>
                    </DropdownItem>
                </div>
            </DropdownItems>
        </Dropdown>
    )
}

export default WorkspaceMenuView;
