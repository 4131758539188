import BasicButton from "../UI/Buttons/BasicButton";
import InputLabel from "../UI/Inputs/InputLabel";
import InputText from "../UI/Inputs/InputText";

const UserSettings = (props) => {
    return (
        <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6 grid grid-cols-12">
                <div className={'col-span-8'}>
                    <div className='flex flex-row gap-4 justify-between'>
                        <div className={'grow'}>
                            <InputLabel htmlFor="firstname" title={'Firstname'} />
                            <InputText name="firstname" id="firstname" value={props.firstName} onChange={(e) => props.setFirstName(e.target.value)}/>
                        </div>
                        <div className={'grow'}>
                            <InputLabel htmlFor="lastname" title={'Lastname'} />
                            <InputText name="lastname" id="lastname" value={props.lastName} onChange={(e) => props.setLastName(e.target.value)}/>
                        </div>
                    </div>
                    <div className={'mt-3'}>
                        <InputLabel htmlFor="email" title={'Email'} />
                        <InputText name="email" id="email" disabled={true} readOnly={true} value={props.email} onChange={(e) => props.setEmail(e.target.value)}/>
                    </div>
                </div>
                <div className={'col-span-4'}>
                    <div className={'flex justify-end mx-auto'}>
                        <img className="relative h-40 w-40 rounded-full" src={props.avatar}
                             alt=""/>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <BasicButton title={'Update settings'} type={'primary'} onClick={() => props.onUpdateUser()}/>
            </div>
        </div>
    )
}

export default UserSettings;
