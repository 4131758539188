import {useParams} from "react-router-dom";
import React, {useCallback, useState} from "react";
import DomainTable from "../components/Domain/DomainTable";
import BreachTable from "../components/Email/BreachTable";
import AssetPage from "../components/Asset/AssetPage/AssetPage";

export default function Email() {
    const params = useParams();
    const initialAsset = {
        asset: {
            id: '',
            name: '',
            tags: [],
        }
    };

    const [description, setDescription] = useState({});
    const [domains, setDomains] = useState([]);
    const [breaches, setBreaches] = useState([]);

    const onUpdateAsset = useCallback((asset) => {
        setDomains(asset.domain != null ? [asset.domain] : []);
        setBreaches(asset.raw_asset.breaches ?? []);

        setDescription({
            'name': asset.asset.id,
        });
    }, []);

    return (
        <AssetPage workspaceId={params.workspace}
                   assetType={'emails'}
                   assetId={params.email}
                   initialAsset={initialAsset}
                   onUpdateAsset={onUpdateAsset}
                   description={description}>
            <div className='col-span-12 lg:col-span-4'>
                <DomainTable domains={domains}/>
            </div>
            <div className='col-span-12 lg:col-span-8'>
                <BreachTable breaches={breaches}/>
            </div>
        </AssetPage>
    )
}
