import {useNavigate} from "react-router-dom";

const TeamWorkspaceTableItem = (props) => {
    const navigate = useNavigate();

    return (
        <tr onClick={() => navigate('/workspaces/' + props.id)} className='hover:cursor-pointer'>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                        <span className="h-10 w-10 rounded-full flex items-center justify-center bg-gray-100 uppercase">
                            {props.name.slice(0, 2)}
                        </span>
                    </div>
                    <div className="ml-4">
                        <div className="font-medium text-gray-900">{props.name}</div>
                        <div className="text-gray-500">{props.description}</div>
                    </div>
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                {props.refresh_interval}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {(props.assets ?? []).join(', ')}
            </td>
        </tr>
    )
}

export default TeamWorkspaceTableItem;