import ThreatTableItem from "./ThreatTableItem";
import Table from "../Table/Table";
import EmptyThreatState from "./EmptyThreatState";

const levelWeights = {
    'low': 0,
    'medium': 1,
    'high': 2,
};

const sortThreats = (a, b) => {
    const aWeight = levelWeights[a['level']];
    const bWeight = levelWeights[b['level']];

    return bWeight - aWeight;
}

const ThreatTable = (props) => {
    const totalThreats = props.threats.reduce((acc, cur) => (cur.assets ?? [0]).length + acc, 0);
    const threats = (props.threats ?? []).sort(sortThreats).map((threat) => {
        return {
            tableKey: threat.id,
            ...threat
        };
    });

    const TableItem = (threat) => {
        return <ThreatTableItem {...threat} onMuteThreat={props.onMuteThreat} onUnMuteThreat={props.onUnMuteThreat}/>;
    }

    return (
        <Table className={props.className} title={'Threats'} keys={['Severity', 'Description', '']}
               items={threats} tableItem={TableItem} count={totalThreats} emptyState={EmptyThreatState}/>
    )
}

export default ThreatTable;