import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import PageTitle from "../../components/Page/PageTitle";
import {useContext, useEffect, useState} from "react";
import AppContext from "../../store/app-context";
import WorkspaceEdit from "../../components/Workspace/WorkspaceEdit";
import {getWorkspace, handleDisconnect, updateWorkspace, validateAsset} from "../../requests";
import {useNavigate, useParams} from "react-router-dom";
import Alert from "../../components/Alert/Alert";

export default function WorkspacesEdit() {
    const appContext = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();

    const [workspace, setWorkspace] = useState({
        id: '',
        name: '',
        description: '',
        role: ''
    });

    const [pages, setPages] = useState([]);

    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        getWorkspace(appContext.token, params.workspace)
            .then((workspace) => {
                setWorkspace(workspace);

                setPages([
                    {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
                    {id: 2, name: workspace.name, href: '/workspaces/' + workspace.id},
                    {id: 3, name: 'Edit', href: '/edit', current: true},
                ]);
            })
            .catch((error) => handleDisconnect(error, appContext));
    }, [appContext, params.workspace, appContext.token]);

    const onEditWorkspace = (newWorkspace) => {
        setErrorMessages([]);

        validateAsset(appContext.token, newWorkspace.assets).then((errors) => {
            if (errors.length > 0) {
                setErrorMessages(errors);
                return;
            }

            updateWorkspace(appContext.token, workspace.id, newWorkspace)
                .then((workspace) => {
                    navigate('/workspaces/' + workspace.id);
                })
                .catch((error) => handleDisconnect(error, appContext));
        }).catch((error) => handleDisconnect(error, appContext));
    };

    return (<Page>
        <PageHeader pages={pages}>
            <PageTitle title={'Edit ' + workspace.name + ' workspace'}/>
        </PageHeader>
        <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
                <Alert className={'mb-8'} type={'danger'} title={'Invalid assets'} messages={errorMessages}
                       show={errorMessages.length > 0}/>
                <WorkspaceEdit team_id={appContext.currentTeam.id}
                               onSubmit={onEditWorkspace}
                               workspace={workspace}
                               isEditing={true}
                               canCreateSecured={appContext.user.features.includes('SECURED_WORKSPACE')}/>
            </div>
        </div>
    </Page>)
}
