import BasicButton from "../UI/Buttons/BasicButton";
import InputLabel from "../UI/Inputs/InputLabel";
import InputText from "../UI/Inputs/InputText";

const LoginTwoFactor = (props) => {
    return (
        <form className="space-y-6" onSubmit={props.onLogin}>
            <div>
                <InputLabel htmlFor="two_factor_code" title={'Two factor code'}/>
                <InputText name="two_factor_code" required={true} placeholder="352 175" value={props.twoFactorCode}
                           onChange={(e) => props.setTwoFactorCode(e.target.value)}/>
            </div>

            <div>
                <BasicButton type={'primary'} title={'Sign in'} buttonType={'submit'}
                             className={'w-full justify-center'}/>
            </div>
        </form>
    )
};

export default LoginTwoFactor;
