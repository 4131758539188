import {CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XCircleIcon} from "@heroicons/react/solid";

const AlertTypeStyle = {
    'info': {
        'bgColor': 'bg-blue-50',
        'titleColor': 'text-blue-800',
        'textColor': 'text-blue-700',
        'logoColor': 'text-blue-400',
        'logo': InformationCircleIcon,
    },
    'warning': {
        'bgColor': 'bg-yellow-50',
        'titleColor': 'text-yellow-800',
        'textColor': 'text-yellow-700',
        'logoColor': 'text-yellow-400',
        'logo': ExclamationIcon,
    },
    'danger': {
        'bgColor': 'bg-red-50',
        'titleColor': 'text-red-800',
        'textColor': 'text-red-700',
        'logoColor': 'text-red-400',
        'logo': XCircleIcon,
    },
    'success': {
        'bgColor': 'bg-green-50',
        'titleColor': 'text-green-800',
        'textColor': 'text-green-700',
        'logoColor': 'text-green-400',
        'logo': CheckCircleIcon,
    },
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Alert = (props) => {
    const alertType = props.type ?? 'info';
    const alertStyle = AlertTypeStyle[alertType] ?? AlertTypeStyle['info'];
    const Logo = alertStyle.logo;
    const isShown = props.show ?? true;

    return (
        <div className={classNames(
            'rounded-md p-4',
            alertStyle.bgColor,
            props.className ?? '',
            isShown ? '' : 'hidden'
        )}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <Logo className={classNames('h-5 w-5', alertStyle.logoColor)} aria-hidden="true"/>
                </div>
                <div className="ml-3">
                    <h3 className={classNames('text-sm font-medium', alertStyle.titleColor)}>
                        {props.title ?? ''}
                    </h3>
                    <div className={classNames('mt-2 text-sm', alertStyle.textColor)}>
                        <ul className="list-disc space-y-1 pl-5">
                            {
                                (props.messages ?? []).map((message) => <li key={message}>{message}</li>)
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Alert;