import React from "react";
import AssetFilter from "../AssetFilter/AssetFilter";

const AssetListFilters = (props) => {

    return (
        <div className={'flex gap-2'}>
            <AssetFilter assetFilters={props.assetFilters}
                         setAssetFilters={props.setAssetFilters}
                         parentFilterKey={'assetTypes'}
                         filterName={'Types'}/>
            <AssetFilter assetFilters={props.assetFilters}
                         setAssetFilters={props.setAssetFilters}
                         parentFilterKey={'assetStatuses'}
                         filterName={'Statuses'}/>
            <AssetFilter assetFilters={props.assetFilters}
                         setAssetFilters={props.setAssetFilters}
                         parentFilterKey={'assetTags'}
                         filterName={'Tags'}/>
        </div>
    )
}

export default AssetListFilters;
