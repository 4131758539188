import {useNavigate, useParams} from "react-router-dom";
import Page from "../../components/Page/Page";
import {useContext, useEffect} from "react";
import {getInvitation} from "../../requests";
import AppContext from "../../store/app-context";

export default function TeamsInvitation() {
    const appContext = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getInvitation(appContext.token, params.team, params.invitation)
            .then((invitation) => {
                appContext.setInvitation({
                    'team': params.team,
                    'hash': params.invitation,
                    'email': invitation.email,
                });

                return invitation;
            }).then((invitation) => {
            switch (invitation.type) {
                case 'login':
                    navigate('/login');
                    break;
                case 'create_user':
                    navigate('/register');
                    break;
                default:
                    break;
            }
        });
    }, [appContext, params.team, params.invitation, navigate]);

    return (
        <Page>
        </Page>
    )
}
