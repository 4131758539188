import './App.css';
import {BrowserRouter} from "react-router-dom"
import {useContext} from "react";
import AppContext from "./store/app-context";
import AnonymousRouter from "./routers/anonymous";
import LoggedInRouter from "./routers/logged-in";

function App() {
    const appContext = useContext(AppContext);

    // If app context is not initialized wait, block further action to prevent history loss by doing useless catch-all
    // redirections. This happens because when routing is done we do not know yet if the user is logged in, and therefore
    // we redirect him using anonymous routing. When the app context is initialized the URL is lost forever.
    if (!appContext.isInitialized) {
        return;
    }

    return (
        <BrowserRouter>
            {appContext.token === null ? (
                <AnonymousRouter login={appContext.login}/>
            ) : (
                <LoggedInRouter logout={appContext.logout} user={appContext.user}/>
            )}
        </BrowserRouter>
    );
}

export default App;
