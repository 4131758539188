import Page from "../components/Page/Page";
import PageHeader from "../components/Page/PageHeader";
import PageTitle from "../components/Page/PageTitle";
import {useContext, useEffect, useState} from "react";
import AppContext from "../store/app-context";
import {getUser2faContext, setUser2faContext, updateUser, updateUserPassword} from "../requests";
import Alert from "../components/Alert/Alert";
import UserSettings from "../components/User/UserSettings";
import UserPassword from "../components/User/UserPassword";
import UserTwoFactor from "../components/User/UserTwoFactor";

export default function Settings() {
    const appContext = useContext(AppContext);

    const pages = [
        {id: 1, name: 'Settings', href: '/settings', current: true}
    ];

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');

    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessages, setAlertMessages] = useState([]);
    const [alertType, setAlertType] = useState('');

    const [twoFactorContext, setTwoFactorContext] = useState(null);
    const [twoFactorCode, setTwoFactorCode] = useState();

    useEffect(() => {
        setFirstName(appContext.user.name.split(' ')[0]);
        setLastName(appContext.user.name.split(' ')[1]);
        setEmail(appContext.user.email);

        getUser2faContext(appContext.token).then(setTwoFactorContext);

    }, [appContext]);

    const onUpdateUser = () => {
        const name = firstName + ' ' + lastName;

        const user = {
            name: name
        };

        updateUser(appContext.token, appContext.user.id, user).then((token) => {
            appContext.login(token);
        });
    };

    const onChangePassword = () => {
        resetAlert();

        updateUserPassword(appContext.token, oldPassword, newPassword).then((token) => {
            appContext.login(token);
            setAlertTitle('Your password has been changed');
            setAlertMessages([]);
            setAlertType('success');
        }).catch((error) => {
            setAlertTitle('Error while updating your settings');
            setAlertMessages(error.errors);
            setAlertType('danger');
        });
    }

    const onEnableTwoFactor = () => {
        resetAlert();

        setUser2faContext(appContext.token, {
            'secret': twoFactorContext.secret,
            'code': twoFactorCode
        }).then(() => {
            setAlertTitle('2FA has been enabled on your account');
            setAlertMessages([]);
            setAlertType('success');

            setTimeout(() => {
                resetAlert();
            }, 1000);
        }).catch((error) => {
            setAlertTitle('Error while enabling 2FA');
            setAlertMessages(error.errors);
            setAlertType('danger');
        });
    }

    const resetAlert = () => {
        setAlertTitle('');
        setAlertMessages([]);
        setAlertType('');
    };

    return (
        <Page>
            <PageHeader pages={pages}>
                <PageTitle title='User settings'/>
            </PageHeader>
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="mt-5 md:col-span-2 md:mt-0">
                    <Alert type={alertType} className={'mb-5'} title={alertTitle}
                           messages={alertMessages} show={alertTitle !== ''}/>
                    <form method="POST">
                        <UserSettings firstName={firstName} setFirstName={setFirstName}
                                      lastName={lastName} setLastName={setLastName}
                                      email={email} setEmail={setEmail}
                                      onUpdateUser={onUpdateUser}
                                      avatar={appContext.user.avatar}/>
                    </form>
                    <form method="POST" className={'mt-8'}>
                        <UserPassword oldPassword={oldPassword} setOldPassword={setOldPassword}
                                      newPassword={newPassword} setNewPassword={setNewPassword}
                                      repeatNewPassword={repeatNewPassword} setRepeatNewPassword={setRepeatNewPassword}
                                      onChangePassword={onChangePassword}/>
                    </form>
                    {twoFactorContext !== null ? (
                        <form method="POST" className={'mt-8'}>
                            <UserTwoFactor twoFactorContext={twoFactorContext}
                                           twoFactorCode={twoFactorCode} setTwoFactorCode={setTwoFactorCode}
                                           onEnableTwoFactor={onEnableTwoFactor}/>
                        </form>
                    ) : null
                    }
                </div>
            </div>
        </Page>
    )
}
