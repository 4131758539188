import Table from "../Table/Table";
import EmailTableItem from "./EmailTableItem";

const EmailTable = (props) => {
    const emails = (props.emails ?? []).map((email) => {
        return {
            tableKey: email.id,
            ...email
        };
    });

    return (
        <Table className={props.className} title={'Email addresses'}
               keys={['Address']}
               items={emails} tableItem={EmailTableItem}/>
    )
}

export default EmailTable;