import React from 'react';
import {Menu} from "@headlessui/react";

function DropdownButtonImage(props) {
    return (
        <div>
            <Menu.Button
                className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">{props.srOnly}</span>
                <img className="h-8 w-8 rounded-full"
                     src={props.image ?? ''}
                     alt=""/>
            </Menu.Button>
        </div>
    );
}

export default DropdownButtonImage;
