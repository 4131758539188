const DomainTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {props.id}
            </td>
        </tr>
    )
}

export default DomainTableItem;