import Page from "../components/Page/Page";
import {useContext, useEffect, useState} from "react";
import Alert from "../components/Alert/Alert";
import AppContext from "../store/app-context";
import {register} from "../requests";
import {useNavigate} from "react-router-dom";
import InputLabel from "../components/UI/Inputs/InputLabel";
import InputText from "../components/UI/Inputs/InputText";
import BasicButton from "../components/UI/Buttons/BasicButton";

export default function Register(props) {
    const navigate = useNavigate();
    const appContext = useContext(AppContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [invitationHash, setInvitationHash] = useState('');

    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        const invitation = appContext.invitation;
        setEmail(invitation?.email ?? '');
        setInvitationHash(invitation?.hash ?? '');

    }, [appContext.invitation]);

    const onRegister = (e) => {
        e.preventDefault();

        register(name, email, password, invitationHash)
            .then(() => navigate('/login'))
            .catch((error) => setErrorMessages(error.errors));
    };

    return (
        <Page>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-12 w-auto" src="/logo.svg" alt="Perimetre logo"/>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Register new account
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <Alert className={'mb-8'} type={'danger'} title={'Unable to register'} messages={errorMessages}
                       show={errorMessages.length > 0}/>

                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={onRegister}>
                        <div>
                            <InputLabel htmlFor="name" title={'Name'}/>
                            <InputText name="name" required={true} placeholder="John Doe"
                                       onChange={(e) => setName(e.target.value)} value={name}/>
                        </div>

                        <div>
                            <InputLabel htmlFor="email" title={'Email address'}/>
                            <InputText name="email" type="email" required={true} placeholder="user@example.org"
                                       defaultValue={email} disabled={true} className={'bg-gray-100'}/>
                        </div>

                        <div>
                            <InputLabel htmlFor="password" title={'Password'}/>
                            <InputText name="password" type="password" required={true} placeholder="*********"
                                       value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </div>

                        <div className="flex items-center justify-between">
                        </div>

                        <div>
                            <BasicButton type={'primary'} title={'Sign up'} buttonType={'submit'} className={'w-full justify-center'}/>
                        </div>
                    </form>
                </div>
            </div>
        </Page>
    )
}
