import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import TableCardHeader from "../Table/TableCardHeader";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function getDayStyle(count, max) {
    const percentage = count / max;

    if (percentage > 0.9) {
        return 'bg-indigo-900 text-white hover:bg-indigo-900';
    } else if (percentage > 0.8) {
        return 'bg-indigo-800 text-white hover:bg-indigo-900';
    } else if (percentage > 0.7) {
        return 'bg-indigo-700 text-white hover:bg-indigo-800';
    } else if (percentage > 0.6) {
        return 'bg-indigo-600 text-white hover:bg-indigo-700';
    } else if (percentage > 0.5) {
        return 'bg-indigo-500 text-white hover:bg-indigo-600';
    } else if (percentage > 0.4) {
        return 'bg-indigo-400 text-white hover:bg-indigo-500';
    } else if (percentage > 0.3) {
        return 'bg-indigo-300 text-black hover:bg-indigo-400';
    } else if (percentage > 0.2) {
        return 'bg-indigo-200 text-black hover:bg-indigo-300';
    } else if (percentage > 0.1) {
        return 'bg-indigo-100 text-black hover:bg-indigo-200';
    }

    return 'bg-white text-gray-900 hover:bg-gray-100';
}

export default function Calendar(props) {
    const max = props.calendar.days.reduce((acc, cur) => acc > cur.count ? acc : cur.count, 0);

    return (
        <Card>
            <TableCardHeader title={props.title ?? props.calendar.name} count={props.calendar.count}/>
            <CardBody>
                <div className="relative grid grid-cols-1 gap-x-14">
                    <section className={classNames('text-center')}>
                        <div className="grid grid-cols-7 text-xs leading-6 text-gray-500">
                            <div>M</div>
                            <div>T</div>
                            <div>W</div>
                            <div>T</div>
                            <div>F</div>
                            <div>S</div>
                            <div>S</div>
                        </div>
                        <div
                            className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                            {props.calendar.days.map((day, dayIdx) => (
                                <button
                                    key={day.date}
                                    type="button"
                                    className={classNames(
                                        day.isCurrentMonth ? getDayStyle(day.count ?? 0, max) : 'bg-gray-50 text-gray-400',
                                        dayIdx === 0 && 'rounded-tl-lg',
                                        dayIdx === 6 && 'rounded-tr-lg',
                                        dayIdx === props.calendar.days.length - 7 && 'rounded-bl-lg',
                                        dayIdx === props.calendar.days.length - 1 && 'rounded-br-lg',
                                        'relative py-1.5 focus:z-10'
                                    )}>
                                    <time
                                        dateTime={day.date}
                                        className={classNames(
                                            day.isToday && 'text-indigo-600 text-md font-bold',
                                            'mx-auto flex h-7 w-7 items-center justify-center'
                                        )}>
                                        {day.date.split('-').pop().replace(/^0/, '')}
                                    </time>
                                </button>
                            ))}
                        </div>
                    </section>
                </div>
            </CardBody>
        </Card>
    )
}
