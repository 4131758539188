import Page from "../components/Page/Page";
import {useContext, useEffect, useState} from "react";
import {login} from "../requests";
import Alert from "../components/Alert/Alert";
import LoginCredentials from "../components/Login/LoginCredentials";
import LoginTwoFactor from "../components/Login/LoginTwoFactor";
import AppContext from "../store/app-context";

export default function Login(props) {
    const appContext = useContext(AppContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const [isTwoFactorRequired, setIsTwoFactorRequired] = useState(false);

    const [errorMessages, setErrorMessages] = useState([]);
    const [infoMessages, setInfoMessages] = useState([]);

    useEffect(() => {
        if (appContext.invitation !== null) {
            setInfoMessages(['Login to join the team ' + appContext.invitation.team]);
        }
    }, [appContext.invitation]);

    const onLogin = (e) => {
        setErrorMessages([]);

        e.preventDefault();

        login(email, password, twoFactorCode).then((token) => {
            props.login(token);
        }).catch((error) => {
            if (error.code === 'MISSING_TWO_FACTOR_AUTHENTICATION') {
                setIsTwoFactorRequired(true);
            } else {
                setErrorMessages(error.errors);
            }
        });
    };

    return (
        <Page>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-12 w-auto" src="/logo.svg" alt="Perimetre logo"/>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {isTwoFactorRequired ? '2FA requirements' : 'Sign in to your account'}
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <Alert className={'mb-8'} type={'info'} title={'Information'} messages={infoMessages}
                       show={infoMessages.length > 0}/>
                <Alert className={'mb-8'} type={'danger'} title={'Unable to log in'} messages={errorMessages}
                       show={errorMessages.length > 0}/>

                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    {isTwoFactorRequired ? (
                        <LoginTwoFactor twoFactorCode={twoFactorCode} setTwoFactorCode={setTwoFactorCode}
                                        onLogin={onLogin}/>
                    ) : (
                        <LoginCredentials email={email} setEmail={setEmail}
                                          password={password} setPassword={setPassword}
                                          onLogin={onLogin}/>
                    )}
                </div>
            </div>
        </Page>
    )
}
