import {useParams} from "react-router-dom";
import React, {useCallback, useState} from "react";
import AssetPage from "../components/Asset/AssetPage/AssetPage";
import RecordTable from "../components/Record/RecordTable";

export default function Email() {
    const params = useParams();
    const initialAsset = {
        asset: {
            id: '',
            name: '',
            tags: [],
        }
    };

    const [description, setDescription] = useState({});
    const [records, setRecords] = useState([]);

    const onUpdateAsset = useCallback((asset) => {
        setRecords(asset.records ?? []);

        setDescription({
            'subject': asset.raw_asset.subject,
            'issuer': asset.raw_asset.issuer,
            'not before': new Date(asset.raw_asset.not_before).toLocaleDateString('en-CA'),
            'not after': new Date(asset.raw_asset.not_after).toLocaleDateString('en-CA'),
            'dns names': asset.raw_asset.dns_names.join(', '),
        });
    }, []);

    const assetDisplayFn = useCallback((asset) => {
        return ((asset.raw_asset ?? {}).subject ?? '').replace('CN=', '') ?? '';
    }, []);

    return (
        <AssetPage workspaceId={params.workspace}
                   assetType={'certificates'}
                   assetId={params.certificate}
                   assetDisplayField={'name'}
                   assetDisplayFn={assetDisplayFn}
                   initialAsset={initialAsset}
                   onUpdateAsset={onUpdateAsset}
                   description={description}>
            <div className='col-span-12 flex flex-col gap-y-8'>
                <RecordTable className={'max-h-80'} records={records}/>
            </div>
        </AssetPage>
    )
}
