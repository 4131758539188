import React, {useCallback, useState} from "react";
import {useParams} from "react-router-dom";
import AssetPage from "../components/Asset/AssetPage/AssetPage";
import RecordTable from "../components/Record/RecordTable";
import HostTable from "../components/Host/HostTable";
import WebsiteTable from "../components/Website/WebsiteTable";
import ServiceMetadata from "../components/Service/Metadata/ServiceMetadata";

const LeftSide = (props) => {
    return props.asset.raw_asset &&
        <ServiceMetadata className={'h-80'}
                         metadata={props.asset.raw_asset.metadata}
                         type={props.asset.raw_asset.name}/>;
}

export default function Service(props) {
    const params = useParams();
    const initialAsset = {
        asset: {
            id: '',
            name: '',
            tags: [],
        }
    };

    const [hosts, setHosts] = useState([]);
    const [records, setRecords] = useState([]);
    const [websites, setWebsites] = useState([]);

    const onUpdateAsset = useCallback((asset) => {
        setHosts(asset.host != null ? [asset.host] : []);
        setRecords(asset.records ?? []);
        setWebsites(asset.websites ?? []);
    }, []);

    return (
        <AssetPage workspaceId={params.workspace}
                   assetType={'services'}
                   assetId={params.service}
                   initialAsset={initialAsset}
                   onUpdateAsset={onUpdateAsset}
                   leftSide={LeftSide}>
            <div className='md:col-span-6 col-span-12'>
                <RecordTable className={'max-h-80'} records={records}/>
            </div>
            <div className='md:col-span-6 col-span-12'>
                <HostTable className={'max-h-80'} hosts={hosts}/>
            </div>
            <div className='col-span-12'>
                <WebsiteTable className={'max-h-80'} websites={websites}/>
            </div>
        </AssetPage>
    )
}
