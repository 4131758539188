import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Page from "../../../components/Page/Page";
import PageHeader from "../../../components/Page/PageHeader";
import {viewWorkspaceReport} from "../../../requests";
import AppContext from "../../../store/app-context";

export default function WorkspaceReportsView() {
    const appContext = useContext(AppContext);
    const params = useParams();

    const [pages, setPages] = useState([]);
    const [reportHTML, setReportHTML] = useState();

    useEffect(() => {
        setPages([
            {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
            {id: 2, name: params.workspace, href: '/workspaces/' + params.workspace, current: false},
            {id: 3, name: 'Reports', href: '/workspaces/' + params.workspace + '/reports', current: false},
            {
                id: 4,
                name: params.report,
                href: '/workspaces/' + params.workspace + '/reports/' + params.report,
                current: true
            },
        ]);

        viewWorkspaceReport(appContext.token, params.workspace, params.report).then(setReportHTML);

    }, [appContext, params.workspace, params.report, appContext.token]);

    return (
        <Page>
            <PageHeader pages={pages}>
            </PageHeader>
            <div dangerouslySetInnerHTML={{__html: reportHTML}}/>
        </Page>
    )
}
