import {useRef, useState} from 'react'
import BasicButton from "../UI/Buttons/BasicButton";
import Modal from "../UI/Modal/Modal";
import InputLabel from "../UI/Inputs/InputLabel";
import InputText from "../UI/Inputs/InputText";
import InputSelect from "../UI/Inputs/InputSelect";
import InputSelectOption from "../UI/Inputs/InputSelectOption";

const NewInvitationModal = (props) => {
    const cancelButtonRef = useRef(null);
    const [invitation, setInvitation] = useState({
        email: '',
        role: '',
    });

    const onAddMember = () => {
        props.setOpen(false)
        props.onInviteMember(invitation);
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.setOpen} cancelButtonRef={cancelButtonRef}>
            <div>
                <div>
                    <InputLabel htmlFor="email" title={'Email'}/>
                    <InputText type={'email'} name="email" id="email" placeholder="user@example.org" value={invitation.email} onChange={(e) => setInvitation({...invitation, email: e.target.value})}/>
                </div>
                <div className={'mt-5'}>
                    <InputLabel htmlFor="role" title={'Role'}/>
                    <InputSelect id="role" name="role" value={invitation.role} onChange={(e) => setInvitation({...invitation, role: e.target.value})}>
                        {props.availableRoles.map((role) => <InputSelectOption key={role} id={role} value={role} title={role[0].toUpperCase() + role.slice(1)} /> )}
                    </InputSelect>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <BasicButton title={'Cancel'} type={'secondary'} className={'justify-center'}
                             onClick={() => props.setOpen(false)}/>
                <BasicButton title={'Invite member'} type={'primary'} className={'justify-center'}
                             onClick={() => onAddMember()}/>
            </div>
        </Modal>
    )
}

export default NewInvitationModal;
