import RecordTable from "../components/Record/RecordTable";
import React, {useCallback, useState} from "react";
import {useParams} from "react-router-dom";
import AssetPage from "../components/Asset/AssetPage/AssetPage";
import DescriptionList from "../components/DescriptionList";
import RedirectTable from "../components/Website/RedirectTable";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";

const TopSide = (props) => {
    return props.asset.raw_asset.screenshot !== undefined ? (
        <div className='col-span-12 lg:col-span-12'>
            <Card className={'flex items-center'}>
                <CardBody>
                    <img src={"data:image/png;base64, " + props.asset.raw_asset.screenshot.viewport_screenshot} alt={""}
                         className={'max-h-96'}/>
                </CardBody>
            </Card>
        </div>
    ) : null;
}

export default function Website(props) {
    const params = useParams();
    const initialAsset = {
        asset: {
            name: '',
            tags: [],
        },
        raw_asset: {
            title: '',
            url: '',
            address: '',
            meta: {},
            headers: {},
            status_code: 0
        },
        records: [],
    };

    const [description, setDescription] = useState({});
    const [headers, setHeaders] = useState({});
    const [meta, setMeta] = useState({});
    const [redirects, setRedirects] = useState([]);
    const [records, setRecords] = useState([]);

    const onUpdateAsset = useCallback((asset) => {
        setHeaders(asset.raw_asset.headers);
        setMeta(asset.raw_asset.meta);
        setRedirects(asset.raw_asset.redirects ?? []);
        setRecords(asset.records ?? []);

        setDescription({
            'title': asset.raw_asset.title,
            'url': asset.raw_asset.url,
            'address': asset.raw_asset.address,
            'status': asset.raw_asset.status_code,
        });
    }, []);

    const assetDisplayFn = useCallback((asset) => {
        return asset.asset.name;
    }, []);

    return (
        <AssetPage workspaceId={params.workspace}
                   assetType={'websites'}
                   assetId={params.website}
                   assetDisplayFn={assetDisplayFn}
                   initialAsset={initialAsset}
                   onUpdateAsset={onUpdateAsset}
                   topSide={TopSide}
                   description={description}>
            <div className='col-span-12 lg:col-span-6'>
                <DescriptionList className={'h-90'} title={'Headers'} value={headers}/>
            </div>
            <div className='col-span-12 lg:col-span-6'>
                <DescriptionList className={'h-90'} title={'Meta'} value={meta}/>
            </div>
            {records.length > 0 ? (
                <div className='col-span-12 flex flex-col gap-y-8'>
                    <RecordTable className={'max-h-80'} records={records}/>
                </div>
            ) : null}
            {redirects.length > 0 ? (
                <div className='col-span-12 flex flex-col gap-y-8'>
                    <RedirectTable className={'max-h-80'} redirects={redirects}/>
                </div>
            ) : null}
        </AssetPage>
    )
}
