import Table from "../Table/Table";
import TeamTableItem from "./TeamTableItem";

const TeamTable = (props) => {
    const teams = (props.teams ?? []).map((team) => {
        return {
            tableKey: team.id,
            ...team
        };
    });

    return (
        <Table className={props.className} title={'Teams'} keys={['Name', 'Created At', 'Members', 'Workspaces', 'Role']}
               items={teams} tableItem={TeamTableItem}/>
    )
}

export default TeamTable;