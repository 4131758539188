import TagTableItem from "./TagTableItem";
import Table from "../../Table/Table";
import TagTableEmptyState from "./TagTableEmptyState";

const TagTable = (props) => {
    const tags = (props.tags ?? []).map((tag) => {
        return {
            tableKey: tag.id,
            ...tag
        };
    });

    const TableItem = (tag) => {
        return <TagTableItem {...tag}
                             onEditTag={props.onEditTag}
                             showEdit={props.showEdit}
                             onDeleteTag={props.onDeleteTag}
                             showDelete={props.showDelete}/>
    }

    return (
        <Table className={props.className}
               title={'Tags'}
               keys={['Nom', 'Description', '']}
               items={tags}
               tableItem={TableItem}
               usePagination={props.usePagination ?? true}
               paginationSize={props.paginationSize ?? 10}
               emptyState={TagTableEmptyState}/>
    )
}

export default TagTable;
