import {Fragment} from "react";
import {EyeIcon} from "@heroicons/react/solid";

const SeenActivity = (props) => {
    return (
        <Fragment>
            <div>
                <div className="relative px-1">
                    <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                        <EyeIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
                    </div>
                </div>
            </div>
            <div className="min-w-0 flex-1 py-0">
                <div className="text-sm leading-8 text-gray-500">
                    <span className="mr-0.5">
                        {props.when ?? 'First'} seen
                    </span>{' '}
                    <span className="whitespace-nowrap">
                        {new Date(props.activity.date).toLocaleDateString('en-ca')}
                    </span>
                </div>
            </div>
        </Fragment>
    )
}

export default SeenActivity;