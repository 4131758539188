import {useContext, useEffect, useState} from 'react'
import {SearchIcon} from '@heroicons/react/solid'
import {EmojiSadIcon, GlobeIcon} from '@heroicons/react/outline'
import {Combobox} from '@headlessui/react'
import {search} from "../requests";
import AppContext from "../store/app-context";
import Modal from "./UI/Modal/Modal";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const CommandPalette = (props) => {
    const appContext = useContext(AppContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [matches, setMatches] = useState([]);

    const getAssetUrl = (asset) => {
        switch (asset['category']) {
            case 'records':
                return '#';
            default:
                return '/workspaces/' + asset['workspace'] + '/' + asset['category'] + '/' + asset['id'];
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm === '') {
                setMatches([]);
                return;
            }

            search(appContext.token, searchTerm, appContext.currentTeam.id).then((matches) => {
                setMatches(matches
                    .filter(match => match['category'] !== 'records')
                    .map(match => {
                        return {
                            ...match,
                            'url': getAssetUrl(match),
                        }
                    }));
            });
        }, 750);

        return () => clearTimeout(delayDebounceFn);
    }, [appContext.token, appContext.currentTeam.id, searchTerm]);

    const groups = matches.reduce((groups, item) => {
        return {...groups, [item.category]: [...(groups[item.category] || []), item]}
    }, {});

    return (
        <Modal isOpen={props.open} onClose={props.setOpen} afterLeave={() => setSearchTerm('')} className={'!p-0'}>
            <Combobox onChange={(item) => (window.location = item.url)}>
                <div className="relative">
                    <SearchIcon
                        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                        onChange={(event) => setSearchTerm(event.target.value)}
                    />
                </div>

                {searchTerm === '' && (
                    <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                        <GlobeIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
                        <p className="mt-4 font-semibold text-gray-900">Search for assets and
                            workspaces</p>
                        <p className="mt-2 text-gray-500">
                            Quickly access assets and workspaces by running a global search.
                        </p>
                    </div>
                )}

                {matches.length > 0 && (
                    <Combobox.Options static
                                      className="max-h-80 scroll-pt-11 scroll-pb-2 space-y-2 overflow-y-auto pb-2">
                        {Object.entries(groups).map(([category, items]) => (
                            <li key={category}>
                                <h2 className="bg-gray-100 py-2.5 px-4 text-xs font-semibold text-gray-900">{category}</h2>
                                <ul className="mt-2 text-sm text-gray-800">
                                    {items.map((item) => (
                                        <Combobox.Option
                                            key={item.id}
                                            value={item}
                                            className={({active}) =>
                                                classNames('cursor-default select-none px-4 py-2', active && 'bg-indigo-600 text-white')
                                            }>
                                            {item.name}
                                        </Combobox.Option>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </Combobox.Options>
                )}

                {searchTerm !== '' && matches.length === 0 && (
                    <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                        <EmojiSadIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
                        <p className="mt-4 font-semibold text-gray-900">No results found</p>
                        <p className="mt-2 text-gray-500">We couldn’t find anything with that term.
                            Please try again.</p>
                    </div>
                )}
            </Combobox>
        </Modal>
    )
}

export default CommandPalette;
