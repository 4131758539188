import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import PageTitle from "../../components/Page/PageTitle";
import {createInvitation, deleteInvitation, getTeam, handleDisconnect} from "../../requests";
import AppContext from "../../store/app-context";
import MemberTable from "../../components/Member/MemberTable";
import DescriptionList from "../../components/DescriptionList";
import TeamWorkspaceTable from "../../components/Team/TeamWorkspaceTable";
import TeamInvitationTable from "../../components/Team/TeamInvitationTable";
import NewInvitationModal from "../../components/Team/NewInvitationModal";

const getAvailableRoles = (userRole) => {
    switch (userRole) {
        case 'owner':
            return ['none', 'viewer', 'editor', 'owner'];
        case 'editor':
            return ['none', 'viewer', 'editor'];
        default:
            return [];
    }
};

export default function TeamsView() {
    const appContext = useContext(AppContext);
    const params = useParams();

    const [team, setTeam] = useState({
        id: '',
        name: '',
        created_at: '',
        members: [],
        workspaces: [],
        invitations: [],
        role: ''
    });

    const [pages, setPages] = useState([]);

    const [description, setDescription] = useState({
        'name': '',
        'members': 0,
        'workspaces': 0,
        'created at': '',
    });

    const [isModalShown, setModalShown] = useState(false);

    useEffect(() => {
        getTeam(appContext.token, params.team)
            .then(setTeam)
            .catch((error) => handleDisconnect(error, appContext));
    }, [appContext, params.team, appContext.token]);

    useEffect(() => {
        setPages([
            {id: 1, name: 'Teams', href: '/teams', current: false},
            {id: 2, name: team.name, href: '/teams/' + team.id, current: true},
        ]);

        setDescription({
            'name': team.name,
            'members': team.members.length,
            'workspaces': team.workspaces.length,
            'created at': team.created_at,
        });
    }, [team]);

    const onInviteMember = (member) => {
        createInvitation(appContext.token, team.id, member).then(() => {
            getTeam(appContext.token, params.team)
                .then(setTeam)
                .catch((error) => handleDisconnect(error, appContext));
        });
    }

    const onCancelInvitation = (invitationId) => {
        deleteInvitation(appContext.token, team.id, invitationId).then(() => {
            getTeam(appContext.token, params.team)
                .then(setTeam)
                .catch((error) => handleDisconnect(error, appContext));
        });
    }

    return (
        <Page>
            <PageHeader pages={pages}>
                <PageTitle title={team.name + ' team'}/>
            </PageHeader>
            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 grid grid-cols-12 gap-3">
                        <div className={team.members.length > 0 ? 'col-span-6' : 'col-span-12'}>
                            <DescriptionList className={'h-80'} title={'Information'} value={description}/>
                        </div>
                    </div>
                    {team.members.length > 0 ? (
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <MemberTable members={team.members}
                                         currentUser={appContext.user.email}
                                         onInviteMember={() => setModalShown(true)}/>
                        </div>
                    ) : null}
                    {team.invitations.length > 0 ? (
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <TeamInvitationTable invitations={team.invitations}
                                                 onCancelInvitation={onCancelInvitation}/>
                        </div>
                    ) : null}
                    {team.workspaces.length > 0 ? (
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <TeamWorkspaceTable workspaces={team.workspaces}/>
                        </div>
                    ) : null}
                </div>
            </div>
            <NewInvitationModal
                isOpen={isModalShown}
                setOpen={setModalShown}
                onInviteMember={onInviteMember}
                availableRoles={getAvailableRoles(appContext.currentTeam.role)}/>
        </Page>
    )
}
