import {useParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import PageTitle from "../../components/Page/PageTitle";
import {
    addWorkspaceMember,
    getTeamMembers,
    getWorkspace,
    getWorkspaceMembers,
    handleDisconnect,
    setWorkspaceMembers
} from "../../requests";
import AppContext from "../../store/app-context";
import MemberTable from "../../components/Member/MemberTable";
import NewMemberModal from "../../components/Member/NewMemberModal";

export default function WorkspacesMembers() {
    const appContext = useContext(AppContext);
    const params = useParams();

    const [workspaceMembers, setWorkspaceMembersState] = useState([]);
    const [availableNewMembers, setAvailableNewMembers] = useState([]);

    const [workspace, setWorkspace] = useState({
        id: '',
        name: '',
        description: '',
        role: ''
    });

    const [pages, setPages] = useState([]);

    const [isModalShown, setModalShown] = useState(false);

    const onChangeMemberRole = (email, newRole) => {
        const newMembers = workspaceMembers
            .filter((member) => member.level !== 'team')
            .map((member) => {
                return {
                    ...member,
                    'role': member.email === email ? newRole : member.role,
                };
            });

        setWorkspaceMembers(appContext.token, workspace.id, newMembers).then(() => {
            refreshWorkspaceMembers();
        })
    }

    const onChangeMemberNotificationOption = (email, optionName, optionValue) => {
        const newMembers = workspaceMembers
            .filter((member) => member.level !== 'team')
            .map((member) => {
                return {
                    ...member,
                    notifications: {
                        ...member.notifications,
                        [optionName]: member.email === email ? optionValue : member.notifications[optionName],
                    }
                };
            });

        setWorkspaceMembers(appContext.token, workspace.id, newMembers).then(() => {
            refreshWorkspaceMembers();
        })
    }

    const onRemoveMember = (email) => {
        const newMembers = workspaceMembers
            .filter((member) => member.level !== 'team')
            .filter((member) => member.email !== email);

        setWorkspaceMembers(appContext.token, workspace.id, newMembers).then(() => {
            refreshWorkspaceMembers();
        })
    }

    const onAddMember = (email, role) => {
        addWorkspaceMember(appContext.token, workspace.id, {
            'email': email,
            'role': role,
            'notifications': [],
        }).then(() => {
            refreshWorkspaceMembers();
        })
    }

    const refreshWorkspaceMembers = useCallback(
        () => {
            getWorkspaceMembers(appContext.token, params.workspace)
                .then((workspaceMembers) => {
                    setWorkspaceMembersState(workspaceMembers.map((m) => {
                        return {
                            ...m,
                            availableRoles: getMemberAvailableRoles(m.role),
                        }
                    }));
                })
                .catch((error) => handleDisconnect(error, appContext));
        },
        [appContext, params.workspace],
    );

    const getMemberAvailableRoles = (memberRole) => {
        switch (memberRole) {
            case 'owner':
                return ['owner'];
            case 'editor':
                return ['editor', 'owner'];
            case 'viewer':
                return ['viewer', 'editor', 'owner'];
            default:
                return [];
        }
    };

    useEffect(() => {
        getWorkspace(appContext.token, params.workspace)
            .then((workspace) => {
                setWorkspace(workspace);

                setPages([
                    {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
                    {id: 2, name: workspace.name, href: '/workspaces/' + workspace.id, current: false},
                    {id: 3, name: 'Members', href: '/workspaces/' + workspace.id + '/members', current: true},
                ]);
            })
            .catch((error) => handleDisconnect(error, appContext));

        refreshWorkspaceMembers();
    }, [appContext, params.workspace, appContext.token, refreshWorkspaceMembers]);

    useEffect(() => {
        if (workspace.team_id !== undefined) {
            getTeamMembers(appContext.token, workspace.team_id).then((teamMembers) => {
                const availableNewMembers = teamMembers.filter((teamMember) => !workspaceMembers.some((workspaceMember) => workspaceMember.email === teamMember.email && workspaceMember.level === 'workspace'));
                setAvailableNewMembers(availableNewMembers.map((m) => {
                    return {
                        ...m,
                        availableRoles: getMemberAvailableRoles(m.role),
                    };
                }));
            });
        }
    }, [appContext.token, workspace.team_id, workspaceMembers]);

    return (
        <Page>
            <PageHeader pages={pages}>
                <PageTitle title={workspace.name + ' members'}/>
            </PageHeader>
            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <MemberTable members={workspaceMembers}
                                     onChangeMemberRole={onChangeMemberRole}
                                     onChangeMemberNotificationOption={onChangeMemberNotificationOption}
                                     onRemoveMember={onRemoveMember}
                                     currentUser={appContext.user.email}
                                     onInviteMember={() => setModalShown(true)}/>
                    </div>
                </div>
            </div>
            <NewMemberModal isOpen={isModalShown} setOpen={setModalShown} members={availableNewMembers}
                            onAddMember={onAddMember}/>
        </Page>
    )
}
