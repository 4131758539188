import BasicButton from "../UI/Buttons/BasicButton";
import InputLabel from "../UI/Inputs/InputLabel";
import InputText from "../UI/Inputs/InputText";

const UserPassword = (props) => {
    return (
        <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
                <div>
                    <InputLabel htmlFor="old_password" title={'Old password'}/>
                    <InputText type='password' name="old_password" id="old_password" placeholder={'You old password'} value={props.oldPassword} onChange={(e) => props.setOldPassword(e.target.value)}/>
                </div>
                <div className={'mt-5'}>
                    <InputLabel htmlFor="new_password" title={'New password'}/>
                    <InputText type='password' name="new_password" id="new_password" placeholder={'You new password'} value={props.newPassword} onChange={(e) => props.setNewPassword(e.target.value)}/>
                </div>
                <div className={'mt-3'}>
                    <InputLabel htmlFor="repeat_new_password" title={'Repeat new password'}/>
                    <InputText type='password' name="repeat_new_password" id="repeat_new_password" placeholder={'Repeat your new password'} value={props.repeatNewPassword} onChange={(e) => props.setRepeatNewPassword(e.target.value)}/>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <BasicButton title={'Update password'} type={'primary'} onClick={() => props.onChangePassword()}
                             className={props.newPassword === props.repeatNewPassword ? '' : 'bg-gray-300 hover:bg-gray-300'}/>
            </div>
        </div>
    )
}

export default UserPassword;
