import Table from "../Table/Table";
import TeamInvitationTableItem from "./TeamInvitationTableItem";
import React from "react";

const TeamInvitationTable = (props) => {
    const invitations = (props.invitations ?? []).map((team) => {
        return {
            tableKey: team.id,
            ...team
        };
    });

    const TableItem = (invitation) => {
        return <TeamInvitationTableItem {...invitation}
                                        onCancelInvitation={props.onCancelInvitation}/>;
    }

    return (
        <Table className={props.className}
               title={'Pending invitations'}
               keys={['Email', 'Role', 'Created At', '']}
               items={invitations}
               tableItem={TableItem}/>
    )
}

export default TeamInvitationTable;