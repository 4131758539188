import {useEffect, useState} from "react";
import BasicButton from "../UI/Buttons/BasicButton";
import InputLabel from "../UI/Inputs/InputLabel";
import InputText from "../UI/Inputs/InputText";
import InputSelect from "../UI/Inputs/InputSelect";
import InputSelectOption from "../UI/Inputs/InputSelectOption";
import InputTextarea from "../UI/Inputs/InputTextarea";
import InputCheckbox from "../UI/Inputs/InputCheckbox";

const WorkspaceEdit = (props) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [assets, setAssets] = useState('');
    const [refreshInterval, setRefreshInterval] = useState('none');
    const [isSecured, setIsSecured] = useState(false);
    const isEditing = props.isEditing ?? false;

    const refreshIntervalLabels = {
        'none': 'Never refresh automatically your workspace.',
        'daily': 'Refresh your workspace daily. 245€/month',
        'weekly': 'Refresh your workspace weekly. 40€/month',
        'monthly': 'Refresh your workspace monthly. 20€/month',
    }

    useEffect(() => {
        if (props.workspace !== undefined) {
            setName(props.workspace.name);
            setDescription(props.workspace.description);
            setAssets((props.workspace.assets ?? []).join('\n'));
            setRefreshInterval(props.workspace.refresh_interval);
            setIsSecured(props.workspace.is_secured);
        }
    }, [props.workspace]);

    const onClick = () => {
        const workspace = {
            name: name,
            description: description,
            team_id: props.team_id,
            assets: assets.split('\n').filter((a) => a !== ''),
            refresh_interval: refreshInterval,
            is_secured: isSecured,
        };

        props.onSubmit(workspace);
    };

    return (
        <form action="#" method="POST">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div>
                        <InputLabel htmlFor={'name'} title={'Name'}/>
                        <InputText name={'name'} id={'name'} placeholder={'My workspace'} value={name}
                                   onChange={(e) => setName(e.target.value)}/>
                    </div>

                    <div>
                        <InputLabel htmlFor={'description'} title={'Description'}/>
                        <InputText name={'description'} id={'description'} placeholder={'Description'}
                                   value={description} onChange={(e) => setDescription(e.target.value)}/>
                    </div>

                    <div>
                        <InputLabel htmlFor={'refresh_interval'} title={'Refresh interval'}/>
                        <InputSelect id="refresh_interval" name="refresh_interval"
                                     onChange={(e) => setRefreshInterval(e.target.value)} value={refreshInterval}>
                            <InputSelectOption value={'none'} title={'None'}/>
                            <InputSelectOption value={'daily'} title={'Daily'}/>
                            <InputSelectOption value={'weekly'} title={'Weekly'}/>
                            <InputSelectOption value={'monthly'} title={'Monthly'}/>
                        </InputSelect>
                        <p className="mt-2 text-sm text-gray-500">
                            {refreshIntervalLabels[refreshInterval]}
                        </p>
                    </div>

                    <div>
                        <InputLabel htmlFor={'assets'} title={'Assets (domains, hostnames, IPs, services, websites, emails)'}/>
                        <InputTextarea id="assets" name="assets" rows="3" placeholder="google.com" value={assets}
                                       onChange={(e) => setAssets(e.target.value)}/>
                        <p className="mt-2 text-sm text-gray-500">
                            Put every entry on a separate line.
                        </p>
                    </div>

                    {props.canCreateSecured ? (
                        <div className="relative flex items-start">
                            <div className="min-w-0 flex-1">
                                <InputLabel htmlFor="is_secured" title={'Secured'}/>
                                <p id="is_secured-description" className="text-sm text-gray-500">
                                    Enable threat and changes notifications for every workspace assets.
                                </p>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                                <InputCheckbox id="is_secured" ariaDescribedBy="is_secured-description"
                                               name="is_secured" checked={isSecured}
                                               onChange={(e) => setIsSecured(e.target.checked)}/>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <BasicButton title={isEditing ? 'Edit' : 'Create'} type={'primary'} onClick={(e) => onClick()}/>
                </div>
            </div>
        </form>
    )
}

export default WorkspaceEdit;
