import {Fragment} from "react";
import {PencilIcon} from "@heroicons/react/solid";

const ChangeActivity = (props) => {
    return (
        <Fragment>
            <div>
                <div className="relative px-1">
                    <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                        <PencilIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
                    </div>
                </div>
            </div>
            <div className="min-w-0 flex-1 py-0">
                <div className="text-sm leading-8 text-gray-500 hover:text-primary hover:underline hover:cursor-pointer"
                     onClick={() => props.onClick(props.activity)}>
                    <span className="mr-0.5">
                        Changed
                    </span>{' '}
                    <span className="whitespace-nowrap">
                        {new Date(props.activity.date).toLocaleDateString('en-ca')}
                    </span>
                </div>
            </div>
        </Fragment>
    )
}

export default ChangeActivity;