import SshServiceMetadata from "./SshServiceMetadata";
import EmptyMetadataState from "./EmptyMetadataState";
import FtpServiceMetadata from "./FtpServiceMetadata";
import HttpServiceMetadata from "./HttpServiceMetadata";
import ImapServiceMetadata from "./ImapServiceMetadata";
import Pop3ServiceMetadata from "./Pop3ServiceMetadata";
import SmtpServiceMetadata from "./SmtpServiceMetadata";

const ServiceTypes = {
    'ssh': SshServiceMetadata,
    'ftp': FtpServiceMetadata,
    'http': HttpServiceMetadata,
    'imap': ImapServiceMetadata,
    'pop3': Pop3ServiceMetadata,
    'smtp': SmtpServiceMetadata,
};

const ServiceMetadata = (props) => {
    const ServiceSpecificType = props.metadata !== undefined && Object.keys(props.metadata) > 0 ? (ServiceTypes[props.type] ?? EmptyMetadataState) : EmptyMetadataState;

    return <ServiceSpecificType {...props}/>
}

export default ServiceMetadata;