import React from 'react';

function InputText(props) {
    return (
        <input type={props.type ?? 'text'} name={props.name} id={props.id}
               className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm " + (props.className ?? '')}
               placeholder={props.placeholder} value={props.value}
               onChange={props.onChange} disabled={props.disabled} readOnly={props.readOnly} defaultValue={props.defaultValue} required={props.required}/>
    );
}

export default InputText;
