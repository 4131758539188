import {CheckIcon, PencilIcon, ShieldCheckIcon, XIcon} from "@heroicons/react/solid";
import {useEffect, useState} from "react";
import BasicButton from "../../UI/Buttons/BasicButton";
import InputText from "../../UI/Inputs/InputText";
import H2 from "../../UI/Texts/H2";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const AssetPageTitle = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editingValue, setEditingValue] = useState('');

    useEffect(() => {
        setEditingValue(props.title);
        setIsEditing(false);
    }, [props.title]);

    const onEdit = () => {
        props.onEdit(editingValue);
        setIsEditing(false);
    }

    const onCancel = () => {
        setEditingValue(props.title);
        setIsEditing(false);
    }

    return (
        <div className="flex-1 min-w-0">
            <div className='flex items-center gap-2'>
                {isEditing ? (
                    <>
                        <InputText value={editingValue} onChange={(e) => setEditingValue(e.target.value)}
                                   className={"leading-7 sm:text-3xl sm:truncate !w-auto"}/>
                        <BasicButton type={'noBorder'} onClick={() => onEdit()}
                                     icon={<CheckIcon className="h-6 w-6 text-gray-400 hover:text-green-600"
                                                      aria-hidden="true"/>}/>
                        <BasicButton type={'noBorder'} onClick={() => onCancel()}
                                     icon={<XIcon className="h-6 w-6 text-gray-400 hover:text-red-600"
                                                  aria-hidden="true"/>}/>
                    </>
                ) : (
                    <>
                        {props.isSecured ? <ShieldCheckIcon className={
                            classNames('h-7 w-7', props.isActive ? 'text-green-500' : 'text-gray-400')
                        }/> : null}
                        <H2 title={props.title} className={props.isActive ? 'text-gray-900' : 'text-gray-400'}/>
                        {props.isEditable ?
                            <BasicButton type={'noBorder'} onClick={() => setIsEditing(true)} icon={<PencilIcon
                                className="h-6 w-6 text-gray-400 hover:text-gray-600 md:block hidden"
                                aria-hidden="true"/>}/>
                            : ''
                        }
                    </>
                )}
            </div>
            {props.children}
        </div>
    )
}

export default AssetPageTitle;
