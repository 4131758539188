import {Fragment} from "react";
import {ChatAltIcon} from "@heroicons/react/solid";

const UpdateActivity = (props) => {
    const user = props.activity.user ?? {
        'name': 'Ghost User',
        'avatar': 'https://avatars.githubusercontent.com/u/10137',
    };

    return (
        <Fragment>
            <div className="relative">
                <img className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                     src={user.avatar} alt=""/>
                <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                    <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                </span>
            </div>
            <div className="min-w-0 flex-1">
                <div>
                    <div className="text-sm">
                        <span className="font-medium text-gray-900">
                            {user.name}
                        </span>
                    </div>
                    <p className="mt-0.5 text-sm text-gray-500">
                        Updated workspace the {new Date(props.activity.date).toLocaleDateString('en-ca')}
                    </p>
                </div>
                <div className="mt-2 text-sm text-gray-700">
                    <p>{props.activity.comment}</p>
                </div>
            </div>
        </Fragment>
    )
}

export default UpdateActivity;