import LoadingAssetState from "../../Asset/LoadingAssetState";
import AssetMap from "../../Asset/AssetMap/AssetMap";
import React from "react";

const WorkspaceViewMap = (props) => {
    return (
        props.isLoadingAssetMap ? (
            <LoadingAssetState/>
        ) : (
            <AssetMap assetMap={props.assetMap}
                      assetFilters={props.assetMapFilters}
                      setAssetFilters={props.updateAssetMapFilters}
                      onNodeClick={(e) => props.openAssetPage(props.workspaceId, e)}/>
        )
    )
}

export default WorkspaceViewMap;