import {useEffect, useRef, useState} from 'react'
import MemberSelect from "./MemberSelect";
import BasicButton from "../UI/Buttons/BasicButton";
import Modal from "../UI/Modal/Modal";
import InputLabel from "../UI/Inputs/InputLabel";
import InputSelect from "../UI/Inputs/InputSelect";
import InputSelectOption from "../UI/Inputs/InputSelectOption";

const NewMemberModal = (props) => {
    const cancelButtonRef = useRef(null);
    const [selectedMember,  setSelectedMember] = useState({
        name: '',
        email: '',
        avatar: '',
        role: '',
        availableRoles: [],
    });

    const [selectedRole, setSelectedRole] = useState('viewer');

    useEffect(() => {
        if (props.members.length > 0) {
            setSelectedMember(props.members[0]);
        }
    }, [props.members]);

    useEffect(() => {
        setSelectedRole(selectedMember.availableRoles[0]);
    }, [selectedMember]);

    const onAddMember = () => {
        props.setOpen(false)
        props.onAddMember(selectedMember.email, selectedRole);
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.setOpen} cancelButtonRef={cancelButtonRef}>
            <div>
                <MemberSelect selected={selectedMember} setSelected={setSelectedMember}
                              members={props.members ?? []} label={'Member'}/>
                <div className={'mt-5'}>
                    <InputLabel htmlFor="role" title={'Role'}/>
                    <InputSelect id="role" name="role" onChange={(e) => setSelectedRole(e.target.value)} value={selectedRole}>
                        {selectedMember.availableRoles.map((role) => <InputSelectOption key={role} id={role} value={role} title={role[0].toUpperCase() + role.slice(1)} />)}
                    </InputSelect>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <BasicButton title={'Cancel'} type={'secondary'} onClick={() => props.setOpen(false)} className={'!justify-center'} />
                <BasicButton title={'Add member'} type={'primary'} onClick={() => onAddMember()} className={'!justify-center'} />
            </div>
        </Modal>
    )
}

export default NewMemberModal;
