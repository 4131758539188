import React from 'react';

function InputLabel(props) {
    return (
        <label htmlFor={props.htmlFor}
               className={"block text-sm font-medium text-gray-700 " + (props.srOnly ? 'sr-only' : '')}>{props.title}</label>
    );
}

export default InputLabel;
