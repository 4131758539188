import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import PageTitle from "../../components/Page/PageTitle";
import {getWorkspace, getWorkspaceActivities, handleDisconnect} from "../../requests";
import AppContext from "../../store/app-context";
import Timeline from "../../components/Timeline/Timeline";

export default function WorkspacesActivities() {
    const appContext = useContext(AppContext);
    const params = useParams();

    const [workspace, setWorkspace] = useState({
        id: '',
        name: '',
        description: '',
        role: ''
    });

    const [pages, setPages] = useState([]);

    const [activities, setActivities] = useState([]);

    useEffect(() => {
        getWorkspace(appContext.token, params.workspace)
            .then((workspace) => {
                setWorkspace(workspace);

                setPages([
                    {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
                    {id: 2, name: workspace.name, href: '/workspaces/' + workspace.id, current: false},
                    {id: 3, name: 'Activities', href: '/workspaces/' + workspace.id + '/activities', current: true},
                ]);
            })
            .catch((error) => handleDisconnect(error, appContext));
        getWorkspaceActivities(appContext.token, params.workspace).then(setActivities);
    }, [appContext, params.workspace, appContext.token]);

    return (
        <Page>
            <PageHeader pages={pages}>
                <PageTitle title={workspace.name + ' activities'}/>
            </PageHeader>
            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <Timeline activities={activities}/>
                    </div>
                </div>
            </div>
        </Page>
    )
}
