import React, {useCallback, useState} from "react";
import AssetPage from "../components/Asset/AssetPage/AssetPage";
import {useParams} from "react-router-dom";
import RecordTable from "../components/Record/RecordTable";
import EmailTable from "../components/Email/EmailTable";

export default function Domain() {
    const params = useParams();
    const initialAsset = {
        asset: {
            id: '',
            name: '',
            tags: [],
        }
    };

    const [description, setDescription] = useState({});
    const [records, setRecords] = useState([]);
    const [emails, setEmails] = useState([]);

    const onUpdateAsset = useCallback((asset) => {
        setRecords(asset.records ?? []);
        setEmails(asset.emails ?? []);

        const description = {
            name: asset.asset.id
        };

        if (asset.raw_asset?.whois?.domain_registrar?.registrar_name !== undefined) {
            description['Registrar'] = asset?.raw_asset?.whois?.domain_registrar?.registrar_name;
        }
        if (asset.raw_asset?.whois?.create_date !== undefined) {
            description['Created At'] = asset.raw_asset?.whois?.create_date;
        }
        if (asset.raw_asset?.whois?.update_date !== undefined) {
            description['Updated At'] = asset.raw_asset?.whois?.update_date;
        }
        if (asset.raw_asset?.whois?.expiry_date !== undefined) {
            description['Expiring At'] = asset.raw_asset?.whois?.expiry_date;
        }

        setDescription(description);
    }, []);

    return (
        <AssetPage workspaceId={params.workspace}
                   assetType={'domains'}
                   assetId={params.domain}
                   initialAsset={initialAsset}
                   onUpdateAsset={onUpdateAsset}
                   description={description}>
            <div className='col-span-12 lg:col-span-7'>
                <RecordTable records={records} className={'max-h-96'}/>
            </div>
            <div className='col-span-12 lg:col-span-5'>
                <EmailTable emails={emails} className={'h-full'}/>
            </div>
        </AssetPage>
    )
}
