const Badge = (props) => {

    function labelColor(badgeColor) {
        let hexColor = badgeColor.replace(/^#/, '');
        let r = parseInt(hexColor.substring(0, 2), 16);
        let g = parseInt(hexColor.substring(2, 4), 16);
        let b = parseInt(hexColor.substring(4, 6), 16);

        let luminosity = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
        return luminosity > 0.7 ? "text-black" : "text-white";
    }

    return (
        <span
            className={"inline-flex items-center px-3 py-0.5 rounded-full md:text-sm text-xs font-medium gap-0.5 " + (props.color ? labelColor(props.color) : ' text-white ') + " " + props.classes}
            style={{backgroundColor: props.color}}>
            {props.icon ?? null}
            {props.title}
            {props.onAction ?
                <button onClick={props.onAction} className={'h-4 w-4'}>
                    {props.onActionIcon}
                </button>
                :
                null
            }

        </span>
    )
}

export default Badge;
