const TablePagination = (props) => {
    return (
        <nav className="flex items-center justify-between border-t border-gray-200 bg-gray-50 px-4 py-3 sm:px-6"
             aria-label="Pagination">
            <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{props.paginationSize * props.paginationPage}</span> to <span className="font-medium">{props.paginationSize * (props.paginationPage + 1)}</span> of{' '}
                    <span className="font-medium">{props.paginationTotal}</span> results
                </p>
            </div>
            <div className="flex flex-1 justify-between sm:justify-end">
                <span onClick={() => props.onPreviousPage()}
                   className="relative inline-flex items-center cursor-pointer rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">
                    Previous
                </span>
                <span onClick={() => props.onNextPage()}
                   className="relative ml-3 inline-flex items-center cursor-pointer rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">
                    Next
                </span>
            </div>
        </nav>
    )
}

export default TablePagination;
