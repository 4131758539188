const TableCardHeader = (props) => {
    return (
        <div className="px-4 py-5 sm:px-6 flex justify-between">
            <div className="flex align-baseline items-center gap-3">
                <span>
                    {props.title}
                </span>
                {props.count ? (
                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-200 text-gray-800">
                            {props.count}
                        </span>
                ) : null}
            </div>
            {props.children}
        </div>
    )
}

export default TableCardHeader;