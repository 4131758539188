import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import PageTitle from "../../components/Page/PageTitle";
import {
    createWorkspaceTag,
    deleteWorkspaceTag,
    getWorkspace,
    getWorkspaceTags,
    handleDisconnect,
    updateWorkspaceTag
} from "../../requests";
import AppContext from "../../store/app-context";
import Card from "../../components/Card/Card";
import TagTable from "../../components/Tag/TagTable/TagTable";
import TagEditModal from "../../components/Tag/TagEditModal";
import {PlusIcon} from "@heroicons/react/solid";
import TagDeleteModal from "../../components/Tag/TagDeleteModal";
import BasicButton from "../../components/UI/Buttons/BasicButton";

export default function WorkspacesTags() {
    const appContext = useContext(AppContext);
    const params = useParams();

    const [workspace, setWorkspace] = useState({
        id: '',
        name: '',
        description: '',
        role: ''
    });

    const [pages, setPages] = useState([]);

    const [tags, setTags] = useState([]);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const [selectedTag, setSelectedTag] = useState({})

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        getWorkspace(appContext.token, params.workspace)
            .then((workspace) => {
                setWorkspace(workspace);

                setPages([
                    {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
                    {id: 2, name: workspace.name, href: '/workspaces/' + workspace.id, current: false},
                    {id: 3, name: 'Tags', href: '/workspaces/' + workspace.id + '/tags', current: true},
                ]);
            })
            .catch((error) => handleDisconnect(error, appContext));
        getWorkspaceTags(appContext.token, params.workspace).then(setTags);
    }, [appContext, params.workspace, appContext.token]);

    const onOpenEditTagModal = (tag) => {
        setIsEditModalOpen(true);
        setSelectedTag(tag);
    }

    const onOpenDeleteTagModal = (tag) => {
        setIsDeleteModalOpen(true);
        setSelectedTag(tag);
    }

    const onCloseEditTagModal = () => {
        setIsEditModalOpen(false);
        setSelectedTag({});
    }

    const onCloseDeleteTagModal = () => {
        setIsDeleteModalOpen(false);
        setSelectedTag({});
    }

    const onEditTag = (newTag) => {
        const promise = newTag.id !== undefined ?
            updateWorkspaceTag(appContext.token, workspace.id, newTag.id, newTag) :
            createWorkspaceTag(appContext.token, workspace.id, newTag);

        return promise
            .then(() => getWorkspaceTags(appContext.token, params.workspace))
            .then(setTags)
            .then(() => onCloseEditTagModal());
    }

    const onDeleteTag = () => {

        deleteWorkspaceTag(appContext.token, workspace.id, selectedTag.id)
            .then(() => getWorkspaceTags(appContext.token, params.workspace))
            .then((tags) => {
                setTags(tags)
                return tags;
            })
            .then(removeTagFromPreferences)
            .then(() => onCloseDeleteTagModal());
    }

    function removeTagFromPreferences(tags) {
        if (!appContext.preferences.assetListFilters) {
            return true;
        }

        const assetTagsContextKeys = Object.keys(appContext.preferences.assetListFilters[workspace.id].assetTags);

        const newAssetTagsKeys = assetTagsContextKeys.filter((key) =>
            tags.some((tag) => tag.id.toString() === key)
        );

        const newAssetTags = {
            'assetTags': newAssetTagsKeys.reduce((assetTag, key) => {
                assetTag[key] = appContext.preferences.assetListFilters[workspace.id].assetTags[key];
                return assetTag;
            }, {})
        };

        appContext.updatePreferences({
            ...appContext.preferences,
            assetListFilters: {
                ...appContext.preferences.assetListFilters,
                [workspace.id]: {
                    ...appContext.preferences.assetListFilters[workspace.id],
                    assetTags: newAssetTags.assetTags
                },
            }
        });

        return true;
    }

    return (
        <Page>
            <PageHeader pages={pages}>
                <PageTitle title={workspace.name + ' tags'}/>
                <BasicButton title={'Create tag'} onClick={() => setIsEditModalOpen(true)}
                             icon={<PlusIcon className="h-5 w-5 text-white" aria-hidden="true"/>} type={'primary'}/>
            </PageHeader>
            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <Card>
                            <TagTable className={'max-h-[42rem]'}
                                      tags={tags}
                                      onEditTag={onOpenEditTagModal}
                                      showEdit={workspace.role !== 'viewer'}
                                      onDeleteTag={onOpenDeleteTagModal}
                                      showDelete={workspace.role === 'owner'}/>
                            <TagEditModal isOpen={isEditModalOpen}
                                          onClose={onCloseEditTagModal}
                                          tag={selectedTag}
                                          onEditTag={onEditTag}/>
                            <TagDeleteModal isOpen={isDeleteModalOpen}
                                            onClose={onCloseDeleteTagModal}
                                            tag={selectedTag}
                                            onDeleteTag={onDeleteTag}/>
                        </Card>
                    </div>
                </div>
            </div>
        </Page>
    )
}
