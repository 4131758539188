import Breadcrumbs from "../Breadcrumbs";

const PageHeader = (props) => {
    return (
        <div className='mb-8'>
            <Breadcrumbs pages={props.pages}/>
            <div className="flex items-center justify-between flex-row mt-2">
                {props.children}
            </div>
        </div>
    )
}

export default PageHeader;