import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import PageTitle from "../../components/Page/PageTitle";
import {editAssetSecureStatus, getWorkspace, getWorkspaceAssets, handleDisconnect} from "../../requests";
import AppContext from "../../store/app-context";
import AssetTable from "../../components/Asset/AssetTable/AssetTable";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import {LockClosedIcon} from "@heroicons/react/solid";
import LoadingAssetState from "../../components/Asset/LoadingAssetState";

export default function WorkspacesAssets() {
    const appContext = useContext(AppContext);
    const params = useParams();

    const [workspace, setWorkspace] = useState({
        id: '',
        name: '',
        description: '',
        role: ''
    });

    const [assets, setAssets] = useState([]);
    const [originalAssets, setOriginalAssets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [pages, setPages] = useState([]);

    const onSelectAsset = (currentAsset, selected) => {
        const currentAssetIndex = assets.findIndex((asset) => asset.id === currentAsset.id);

        setAssets(assets.map((asset, assetIndex) => {
            if (assetIndex === currentAssetIndex) {
                return {
                    ...asset,
                    is_secured: selected
                };
            }

            return asset;
        }))
    }

    const onSecuriseAssets = () => {
        const changes = assets.filter(a => !originalAssets.some((oa) => oa.id === a.id && (oa.is_secured === a.is_secured || (oa.is_secured === undefined && a.is_secured === false))));
        const promises = [];

        for (const change of changes) {
            promises.push(
                editAssetSecureStatus(appContext.token, params.workspace, change.type + 's', change.id, change.is_secured)
            );
        }

        Promise.all(promises).then(() => {
            setAssets([]);
            setOriginalAssets([]);
            setIsLoading(true);

            getWorkspaceAssets(appContext.token, params.workspace)
                .then((assets) => assets.filter((asset) => !['record', 'autonomous_system', 'certificate'].includes(asset.type)))
                .then((assets) => {
                    setAssets(assets);
                    setOriginalAssets(assets);
                    setIsLoading(false);
                });
        });
    }

    useEffect(() => {
        getWorkspace(appContext.token, params.workspace)
            .then((workspace) => {
                setWorkspace(workspace);

                setPages([
                    {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
                    {id: 2, name: workspace.name, href: '/workspaces/' + workspace.id, current: false},
                    {id: 3, name: 'Assets', href: '/workspaces/' + workspace.id + '/assets', current: true},
                ]);
            })
            .catch((error) => handleDisconnect(error, appContext));
        getWorkspaceAssets(appContext.token, params.workspace)
            .then((assets) => assets.filter((asset) => !['record', 'autonomous_system', 'certificate'].includes(asset.type)))
            .then((assets) => {
                setAssets(assets);
                setOriginalAssets(assets);
                setIsLoading(false);
            });
    }, [appContext, params.workspace, appContext.token]);

    return (
        <Page>
            <PageHeader pages={pages}>
                <PageTitle title={workspace.name + ' assets'}/>
            </PageHeader>
            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        {
                            isLoading ? (
                                <Card>
                                    <CardBody>
                                        <LoadingAssetState/>
                                    </CardBody>
                                </Card>
                            ) : (
                                <AssetTable className={'max-h-[42rem]'}
                                            assets={assets}
                                            onSelectAsset={onSelectAsset}
                                            isWorkspaceSecured={workspace.is_secured}/>
                            )
                        }
                        <Card>
                            <CardBody>
                                <div className={'flex justify-end'}>
                                    <button type="button" onClick={() => workspace.is_secured ? null : onSecuriseAssets()}
                                            className={'ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ' + (workspace.is_secured ? 'text-gray-600 bg-gray-200' : 'bg-primary hover:bg-indigo-700')}>
                                        <LockClosedIcon className={'h-5 w-5 ' + (workspace.is_secured ? 'text-gray-400' : 'text-white')} aria-hidden="true"/>
                                        <span className={'hidden md:block md:ml-2'}>
                                            Securise assets for {(assets.filter((asset) => asset.is_secured).length * 4).toFixed(2)}€/mo
                                        </span>
                                    </button>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </Page>
    )
}
