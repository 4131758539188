import ServiceTableItem from "./ServiceTableItem";
import Table from "../Table/Table";

const ServiceTable = (props) => {
    const services = (props.services ?? []).map((service) => {
        return {
            tableKey: service.id,
            ...service
        };
    });

    return (
        <Table className={props.className} title={'Services'} keys={['', 'Product', 'Version']}
               items={services} tableItem={ServiceTableItem}/>
    )
}

export default ServiceTable;