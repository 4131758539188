import Badge from "../Badge";
import {Link} from "react-router-dom";

const TeamTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 underline text-primary">
                <Link to={'/teams/' + props.id}>
                    {props.name}
                </Link>
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                <time dateTime={new Date(props.created_at).toLocaleDateString('en-CA')}>
                    {new Date(props.created_at).toLocaleDateString('en-CA')}
                </time>
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                {props.members}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                {props.workspaces}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                <Badge title={props.role} classes={'bg-primary text-white'}/>
            </td>
        </tr>
    )
}

export default TeamTableItem;