import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Chart from "react-apexcharts";

const ThreatPieChart = (props) => {
    const state = {
        options: {
            labels: [],
            legend: {
                position: 'bottom',
            },
            dataLabels: {
                enabled: false,
            },
            colors: [],
        },
        series: [],
    };

    const severityCount = props.threats
        .reduce((obj, threat) => {
            const count = obj[threat.level] || 0;
            return {...obj, [threat.level]: count + threat.assets.length};
        }, {});

    const severities = [
        {level: 'low', color: 'rgba(96, 165, 250)'},
        {level: 'medium', color: 'rgba(251, 146, 60)'},
        {level: 'high', color: 'rgba(248, 113, 113)'}
    ];

    for (const severity of severities) {
        if (severity.level in severityCount) {
            state.options.colors.push(severity.color);
            state.options.labels.push(severity.level);
            state.series.push(severityCount[severity.level]);
        }
    }

    return (
        <Card className={props.className}>
            <CardHeader title={'Threats severity'}/>
            <CardBody>
                <Chart options={state.options} series={state.series} type="donut" height="100%" width="100%"/>
            </CardBody>
        </Card>
    )
}

export default ThreatPieChart;