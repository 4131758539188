import Table from "../Table/Table";
import BreachTableItem from "./BreachTableItem";

const BreachTable = (props) => {
    const breaches = (props.breaches ?? []).map((breach) => {
        return {
            tableKey: breach.name,
            ...breach
        };
    });

    return (
        <Table className={props.className} title={'Breaches'}
               keys={['Name', 'Date', 'Description']}
               items={breaches} tableItem={BreachTableItem}/>
    )
}

export default BreachTable;