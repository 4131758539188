const HostTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {props.id}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                    AS-{props.as_number}
                </span>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {
                    (props.os_matches ?? []).length > 0 ? props.os_matches[0].name : ''
                }
            </td>
        </tr>
    )
}

export default HostTableItem;