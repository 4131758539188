import DescriptionList from "../../DescriptionList";

const SmtpServiceMetadata = (props) => {
    const description = {
        'banner': props.metadata.banner,
    };

    return <DescriptionList className={props.className} title={'Service Metadata'} value={description}/>
}

export default SmtpServiceMetadata;