import React, {useEffect, useRef, useState} from 'react'
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/solid";
import Badge from "../Badge";
import BasicButton from "../UI/Buttons/BasicButton";
import Modal from "../UI/Modal/Modal";

const TagSelectModal = (props) => {
    const cancelButtonRef = useRef(null);

    const [selectedTags, setSelectedTags] = useState({});

    useEffect(() => {
        // Create object of tags by key to easily lookup whether tag is selected
        const assetTags = props.assetTags.reduce((prev, cur) => {
            prev[cur.id] = true;
            return prev;
        }, {});

        setSelectedTags(assetTags);
    }, [props.assetTags, props.isOpen]);

    const selectTag = (tagId, selected) => {
        setSelectedTags({
            ...selectedTags,
            [tagId]: selected
        });
    }

    const onApplyTags = () => {
        const selectedTagIds = Object.entries(selectedTags)
            .filter(([key, value]) => value)
            .map(([key, value]) => key);
        props.onApplyTags(selectedTagIds);
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.setOpen} cancelButtonRef={cancelButtonRef}>
            <div>
                <h3 className="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title">Selected tags</h3>
                <div className={'flex flex-row gap-1 bg-gray-200 rounded-md p-4 flex-wrap'}>
                    {props.workspaceTags.filter((tag) => selectedTags[tag.id]).map((tag) => {
                        return (
                            <Badge key={tag.id} title={tag.name} color={tag.color} classes={''}
                                   onActionIcon={<MinusCircleIcon/>} onAction={(e) => selectTag(tag.id, false)}/>
                        )
                    })}
                </div>
            </div>
            <div className={'mt-4'}>
                <h3 className="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title">Available tags</h3>
                <div className={'flex flex-row gap-1 bg-gray-200 rounded-md p-4 flex-wrap'}>
                    {props.workspaceTags.filter((tag) => !selectedTags[tag.id]).map((tag) => {
                        return (
                            <Badge key={tag.id} title={tag.name} color={tag.color} classes={''}
                                   onActionIcon={<PlusCircleIcon/>} onAction={(e) => selectTag(tag.id, true)}/>
                        )
                    })}
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <BasicButton title={'Apply tag'} type={'primary'} className={'justify-center'}
                             onClick={() => onApplyTags()}/>
                <BasicButton title={'Cancel'} type={'secondary'} className={'justify-center'}
                             onClick={() => props.setOpen(false)}/>
            </div>
        </Modal>
    )
}

export default TagSelectModal;
