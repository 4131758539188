import React from 'react';
import {TrashIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";

function BasicButton(props) {

    const type = {
        primary: {
            style: 'py-2 px-3 bg-primary hover:bg-indigo-700 text-white border border-indigo-500 font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
            icon: '',
            textStyle: '',
        },
        secondary: {
            style: 'py-2 px-3 bg-white hover:bg-gray-50 text-gray-700 border border-gray-300 font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
            icon: '',
            textStyle: '',
        },
        danger: {
            style: 'py-2 px-3 bg-red-600 hover:bg-red-700 text-white border border-red-500 font-medium focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2',
            icon: '',
            textStyle: '',
        },
        action: {
            style: 'py-2 px-3 bg-white border border-gray-300 font-semibold shadow-sm hover:bg-gray-50 text-gray-600',
            icon: '',
            textStyle: '',
        },
        actionDelete: {
            style: 'py-2 px-3 bg-white border border-gray-300 font-semibold shadow-sm hover:bg-gray-50 text-red-500 ml-3',
            icon: <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>,
            textStyle: ''
        },
        noBorder: {
            style: 'text-gray-400 hover:text-gray-500',
            icon: '',
            textStyle: ''
        }
    }

    function Wrap() {
        if (props.link) {
            return (<Link to={props.link}>
                <Button/>
            </Link>)
        } else {
            return <Button/>
        }
    }

    function Button() {
        return (
            <button type={props.buttonType ?? 'button'} onClick={props.onClick}
                    className={"inline-flex items-center rounded-md text-sm " + (type[props.type ?? 'primary'].style ?? '') + ' ' + (props.className ?? '')}>
                {props.icon ?? (type[props.type ?? 'primary'].icon ?? null)}
                <span
                    className={'hidden md:block ' + ((props.icon ?? (type[props.type ?? 'primary'].icon)) && props.title ? 'pl-1.5 ' : '') + (type[props.type ?? 'primary'].textStyle ?? '')}>{props.title}</span>
            </button>
        )
    }

    return (<Wrap/>);
}

export default BasicButton;
