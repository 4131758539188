import SeenActivity from "./SeenActivity";
import CommentActivity from "./CommentActivity";
import TagActivity from "./TagActivity";
import ChangeActivity from "./ChangeActivity";
import NewThreatActivity from "./NewThreatActivity";
import DeletedThreatActivity from "./DeletedThreatActivity";
import RenameActivity from "./RenameActivity";
import SecureStatusUpdateActivity from "./SecureStatusUpdateActivity";
import MuteThreatActivity from "./MuteThreatActivity";
import UpdateActivity from "./UpdateActivity";
import RefreshActivity from "./RefreshActivity";

const FirstSeenActivity = (props) => {
    return <SeenActivity when={'First'} activity={props.activity} onClick={props.onClick}/>;
}

const LastSeenActivity = (props) => {
    return <SeenActivity when={'Last'} activity={props.activity} onClick={props.onClick}/>;
}

const ActivityTypes = {
    'first_seen': FirstSeenActivity,
    'last_seen': LastSeenActivity,
    'comment': CommentActivity,
    'tags': TagActivity,
    'changed': ChangeActivity,
    'new_threat': NewThreatActivity,
    'deleted_threat': DeletedThreatActivity,
    'mute_threat': MuteThreatActivity,
    'unmute_threat': MuteThreatActivity,
    'rename': RenameActivity,
    'secure': SecureStatusUpdateActivity,
    'unsecure': SecureStatusUpdateActivity,
    'update': UpdateActivity,
    'refresh': RefreshActivity,
};

const TimelineItem = (props) => {
    const ActivitySpecificType = ActivityTypes[props.activity.type] ?? null;

    return ActivitySpecificType !== null ?
        <ActivitySpecificType activity={props.activity} onClick={props.onClick}/> : null;
};

export default TimelineItem;