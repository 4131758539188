import {PlusIcon, TemplateIcon} from "@heroicons/react/solid";
import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import React, {useContext, useEffect, useState} from "react";
import PageTitle from "../../components/Page/PageTitle";
import {getWorkspaces, handleDisconnect} from "../../requests";
import AppContext from "../../store/app-context";
import WorkspaceEmptyState from "../../components/Workspace/WorkspaceEmptyState";
import WorkspaceCard from "../../components/Workspace/WorkspaceCard";
import {EyeIcon, ViewListIcon} from "@heroicons/react/outline";
import WorkspaceTable from "../../components/Workspace/WorkspaceTable";
import BasicButton from "../../components/UI/Buttons/BasicButton";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import DropdownButton from "../../components/UI/Dropdown/DropdownButton";
import DropdownItems from "../../components/UI/Dropdown/DropdownItems";
import DropdownItem from "../../components/UI/Dropdown/DropdownItem";
import DropdownItemText from "../../components/UI/Dropdown/DropdownItemText";

export default function Workspaces() {
    const appContext = useContext(AppContext);

    const [workspaces, setWorkspaces] = useState([]);
    const [viewType, setViewType] = useState('card');

    const pages = [
        {id: 1, name: 'Workspaces', href: '/workspaces', current: true}
    ];

    useEffect(() => {
        const preferences = appContext.preferences;
        setViewType(preferences.listViewType ?? 'card');
    }, [appContext]);

    const updateViewType = (viewType) => {
        appContext.updatePreferences({
            ...appContext.preferences,
            listViewType: viewType
        });
        setViewType(viewType);
    }

    const PageActions = () => {
        return (
            <div className={'flex gap-2'}>
                {appContext.currentTeam.role === 'owner' ? (
                    <BasicButton link={'/workspaces/create'} title={'Create workspace'}
                                 icon={<PlusIcon className="h-5 w-5 text-white" aria-hidden="true"/>}/>
                ) : null}
                <Dropdown>
                    <DropdownButton icon={<EyeIcon className="h-5 w-5" aria-hidden="true"/>}/>
                    <DropdownItems className={'py-1'}>
                        <DropdownItem onClick={() => updateViewType('card')} className={'cursor-pointer'}>
                            <DropdownItemText icon={<TemplateIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>} title={'Cards'}/>
                        </DropdownItem>
                        <DropdownItem onClick={() => updateViewType('list')} className={'cursor-pointer'}>
                            <DropdownItemText icon={<ViewListIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>} title={'List'}/>
                        </DropdownItem>
                    </DropdownItems>
                </Dropdown>
            </div>
        )
    };

    useEffect(() => {
        getWorkspaces(appContext.token, appContext.currentTeam.id)
            .then((workspaces) => setWorkspaces(workspaces))
            .catch((error) => handleDisconnect(error, appContext));
    }, [appContext, appContext.token, appContext.currentTeam.id]);

    return (
        <Page>
            <PageHeader pages={pages}>
                <PageTitle title='Workspaces'/>
                <PageActions/>
            </PageHeader>
            {
                workspaces.length === 0 ? (
                    <WorkspaceEmptyState/>
                ) : (
                    viewType === 'card' ? (
                        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            {workspaces.map((workspace) => (
                                <WorkspaceCard key={workspace.id} {...workspace}/>
                            ))}
                        </ul>
                    ) : <WorkspaceTable workspaces={workspaces}/>
                )
            }
        </Page>
    )
}
