import QRCode from "qrcode";
import {useEffect, useState} from "react";
import BasicButton from "../UI/Buttons/BasicButton";
import InputText from "../UI/Inputs/InputText";
import InputLabel from "../UI/Inputs/InputLabel";

const UserTwoFactor = (props) => {
    const [qrCodeSvg, setQrCodeSvg] = useState('');

    useEffect(() => {
        QRCode.toString(props.twoFactorContext.secret_url).then(setQrCodeSvg);
    }, [props.twoFactorContext])

    return (
        <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
                <div>
                    <div dangerouslySetInnerHTML={{__html: qrCodeSvg}} style={{width: '250px', height: '250px'}}></div>
                    <InputLabel htmlFor="2fa_secret_key" title='2FA secret'/>
                    <InputText id="2fa_secret_key" name="2fa_secret_key" defaultValue={props.twoFactorContext.secret} disabled={true}/>
                </div>
                <div className={'mt-5'}>
                    <InputLabel htmlFor="2fa_code" title='2FA code'/>
                    <InputText name="2fa_code" id="2fa_code" placeholder={'Your 2FA 6 digits code'} value={props.twoFactorCode} onChange={(e) => props.setTwoFactorCode(e.target.value)}/>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <BasicButton title={'Enable two factor'} type={'primary'} onClick={() => props.onEnableTwoFactor()}/>
            </div>
        </div>
    )
}

export default UserTwoFactor;
