const getSpanClasses = (statusCode) => {
    if (statusCode >= 500) {
        return 'bg-red-100 text-red-800';
    } else if (statusCode >= 400) {
        return 'bg-yellow-100 text-yellow-800';
    } else if (statusCode >= 300) {
        return 'bg-blue-100 text-blue-800';
    } else if (statusCode >= 200) {
        return 'bg-green-100 text-green-800';
    } else if (statusCode >= 100) {
        return 'bg-blue-100 text-blue-800';
    } else {
        return '';
    }
}

const RedirectTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <div>{props.url}</div>
                <div className={'text-sm text-gray-400 mt-1'}>{props.title}</div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.headers['Location'] ?? ''}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.headers['Server'] ?? ''}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span
                    className={'inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ' + getSpanClasses(props.status_code)}>
                    {props.status_code}
                </span>
            </td>
        </tr>
    )
}

export default RedirectTableItem;