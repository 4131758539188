function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Card = (props) => {
    return (
        <div key={props.key} ref={props.childRef}
            className={classNames("flex flex-col bg-white shadow rounded-lg divide-y divide-gray-200", props.className ?? "")}
            style={props.style ?? {}}>
            {props.children}
        </div>
    );
}

export default Card;
