import React from 'react';

function InputCheckbox(props) {
    return (
        <input type="checkbox" name={props.name} id={props.id} aria-describedby={props.ariaDescribedBy}
               checked={props.checked}
               className={"h-4 w-4 rounded border-gray-300 text-primary focus:ring-indigo-600 " + (props.className ?? '')}
               onChange={props.onChange} defaultChecked={props.defaultChecked}/>
    );
}

export default InputCheckbox;
