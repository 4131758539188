import Table from "../Table/Table";
import RedirectTableItem from "./RedirectTableItem";

const RedirectTable = (props) => {
    const websites = (props.redirects ?? []).map((redirect) => {
        return {
            tableKey: redirect.url,
            ...redirect
        };
    });

    return (
        <Table className={props.className} title={'Redirects'}
               keys={['URL', 'Location', 'Server', 'Status']}
               items={websites} tableItem={RedirectTableItem}/>
    )
}

export default RedirectTable;