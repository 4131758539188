import React from 'react';
import {Menu} from "@headlessui/react";

function Dropdown(props) {
    return (
        <Menu as="div" className={"relative inline-block text-left " + (props.className ?? '')}>
            {props.children}
        </Menu>
    );
}

export default Dropdown;
