import {CheckCircleIcon} from "@heroicons/react/outline";

const EmptyThreatState = () => {
    return (
        <div className="h-full min-h-56 flex flex-col items-center justify-center md:pl-0 md:pr-0 pl-3 pr-3">
            <CheckCircleIcon className="h-12 w-12 text-green-500"/>
            <h3 className="mt-2 text-md font-medium text-gray-900">No threats detected</h3>
            <p className="mt-1 text-sm text-gray-500 text-center">
                You've done a good job securing your infrastructure. Everything's fine.
            </p>
        </div>
    )
}

export default EmptyThreatState;
