import {PaperClipIcon} from "@heroicons/react/solid";
import {useContext, useState} from "react";
import AppContext from "../../store/app-context";
import BasicButton from "../UI/Buttons/BasicButton";
import InputLabel from "../UI/Inputs/InputLabel";
import InputTextarea from "../UI/Inputs/InputTextarea";

const Comment = (props) => {
    const appContext = useContext(AppContext);

    const [comment, setComment] = useState('');

    const onLeaveComment = (comment) => {
        props.onLeaveComment(comment);
        setComment('');
    }

    return (
        <div className="flex items-start md:space-x-4 space-x-0">
            <div className="flex-shrink-0 md:block hidden">
                <img className="inline-block h-10 w-10 rounded-full" src={appContext.user.avatar} alt=""/>
            </div>
            <div className="min-w-0 flex-1">
                <form className="relative">
                    <div
                        className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                        <InputLabel srOnly={true} htmlFor="comment" title={'Add your comment'}/>
                        <InputTextarea rows={3} name="comment" id="comment" placeholder="Add your comment..."
                                       value={comment} className={'border-0'}
                                       onChange={(e) => setComment(e.target.value)}/>
                        <div className="py-2" aria-hidden="true">
                            <div className="py-px">
                                <div className="h-9"/>
                            </div>
                        </div>
                    </div>

                    <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
                        <div className="flex items-center space-x-5">
                            <div className="flex items-center">
                                <BasicButton icon={<PaperClipIcon className="h-5 w-5" aria-hidden="true"/>}
                                             type={'noBorder'}/>
                            </div>
                            <div className="flex items-center">
                            </div>
                        </div>
                        <div className="flex-shrink-0">
                            <BasicButton title={'Post'} type={'primary'} onClick={() => onLeaveComment(comment)}/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Comment;
