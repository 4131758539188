import {ForceGraph2D} from "react-force-graph";
import React, {useEffect, useRef, useState} from "react";
import Card from "../../Card/Card";
import AssetMapFilters from "./AssetMapFilters";

const AssetMap = (props) => {
    const cardRef = useRef(null)

    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);

    useEffect(() => {
        setCanvasWidth(cardRef.current.clientWidth);
        setCanvasHeight(cardRef.current.clientHeight);
    }, [cardRef]);

    const nodeCanvas = (node, ctx, globalScale) => {
        const label = node.name;
        const fontSize = 12 / globalScale;
        ctx.font = `${fontSize}px Sans-Serif`;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'black';
        ctx.fillText(label, node.x, node.y + 7);

        // add ring just for highlighted nodes
        if (Object.entries(node.threats).length > 0) {
            ctx.beginPath();
            ctx.arc(node.x, node.y, 4 * 1.4, 0, 2 * Math.PI, false);
            ctx.fillStyle = 'red';
            ctx.fill();
        }
    };

    return (
        <>
            <AssetMapFilters assetFilters={props.assetFilters}
                             setAssetFilters={props.setAssetFilters}/>
            <Card childRef={cardRef} className={'h-screen mt-3'}>
                <ForceGraph2D width={canvasWidth} height={canvasHeight}
                              nodeId={'key'}
                              graphData={props.assetMap}
                              nodeLabel="name"
                              nodeAutoColorBy="type"
                              linkDirectionalParticles="value"
                              linkDirectionalParticleSpeed={d => d.value * 0.005}
                              nodeCanvasObjectMode={() => 'before'}
                              nodeCanvasObject={nodeCanvas}
                              onNodeClick={(e) => props.onNodeClick(e)}
                              autoPauseRedraw={false}/>
            </Card>
        </>
    )
}

export default AssetMap;