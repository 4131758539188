import DescriptionList from "../../DescriptionList";

const HttpServiceMetadata = (props) => {
    const description = {
        'protocol': props.metadata.response.protocol.name,
        'status code': props.metadata.response.status_line,
        'server': props.metadata.response.headers.server ?? '',
    };

    return <DescriptionList className={props.className} title={'Service Metadata'} value={description}/>
}

export default HttpServiceMetadata;