import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import {useContext, useEffect, useState} from "react";
import PageTitle from "../../components/Page/PageTitle";
import {getTeams, handleDisconnect} from "../../requests";
import AppContext from "../../store/app-context";
import TeamTable from "../../components/Team/TeamTable";

export default function Teams() {
    const appContext = useContext(AppContext);

    const [teams, setTeams] = useState([]);

    const pages = [
        {id: 1, name: 'Teams', href: '/teams', current: true}
    ];

    useEffect(() => {
        getTeams(appContext.token).then(setTeams).catch((error) => handleDisconnect(error, appContext));
    }, [appContext, appContext.token]);

    return (
        <Page>
            <PageHeader pages={pages}>
                <PageTitle title='Teams'/>
            </PageHeader>
            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <TeamTable teams={teams}/>
                    </div>
                </div>
            </div>
        </Page>
    )
}
