import Table from "../Table/Table";
import WebsiteTableItem from "./WebsiteTableItem";

const WebsiteTable = (props) => {
    const websites = (props.websites ?? []).map((website) => {
        return {
            tableKey: website.id,
            ...website
        };
    });

    return (
        <Table className={props.className} title={'Websites'}
               keys={['URL', 'Address', 'Server', 'Status']}
               items={websites} tableItem={WebsiteTableItem}/>
    )
}

export default WebsiteTable;