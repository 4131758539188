import {CogIcon} from "@heroicons/react/outline";
import {
    CurrencyDollarIcon,
    DocumentReportIcon,
    PencilAltIcon,
    RefreshIcon,
    StatusOnlineIcon, TagIcon,
    TrashIcon,
    UsersIcon
} from "@heroicons/react/solid";
import React from "react";
import Dropdown from "../../UI/Dropdown/Dropdown";
import DropdownButton from "../../UI/Dropdown/DropdownButton";
import DropdownItems from "../../UI/Dropdown/DropdownItems";
import DropdownItem from "../../UI/Dropdown/DropdownItem";
import DropdownItemText from "../../UI/Dropdown/DropdownItemText";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const WorkspaceMenuActions = (props) => {
    return (
        <>
            <Dropdown>
                <DropdownButton title={'Actions'} icon={<CogIcon className="h-5 w-5" aria-hidden="true"/>}/>
                <DropdownItems>
                    <div className={'py-1'}>
                        <DropdownItem link={'/workspaces/' + props.workspaceId + '/edit'}
                                      className={props.workspaceRole === 'viewer' ? 'hidden' : ''}>
                            <DropdownItemText title={'Edit'} icon={<PencilAltIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>}/>
                        </DropdownItem>
                        <DropdownItem link={'/workspaces/' + props.workspaceId + '/members'}
                                      className={props.workspaceRole !== 'owner' ? 'hidden' : ''}>
                            <DropdownItemText title={'Members'} icon={<UsersIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>}/>
                        </DropdownItem>
                        <DropdownItem link={'/workspaces/' + props.workspaceId + '/reports'}
                                      className={props.workspaceRole === 'viewer' ? 'hidden' : ''}>
                            <DropdownItemText title={'Reports'} icon={<DocumentReportIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>}/>
                        </DropdownItem>
                        <DropdownItem link={'/workspaces/' + props.workspaceId + '/assets'}
                                      className={props.workspaceRole === 'viewer' ? 'hidden' : ''}>
                            <DropdownItemText title={'Assets'} icon={<CurrencyDollarIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>}/>
                        </DropdownItem>
                        <DropdownItem link={'/workspaces/' + props.workspaceId + '/activities'}
                                      className={props.workspaceRole === 'viewer' ? 'hidden' : ''}>
                            <DropdownItemText title={'Activities'} icon={<StatusOnlineIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>}/>
                        </DropdownItem>
                        <DropdownItem onClick={() => props.onRefreshWorkspace()}
                                      className={props.workspaceRole === 'owner' && props.features.includes('REFRESH_WORKSPACE') ? '' : 'hidden'}>
                            <DropdownItemText title={'Refresh'} icon={<RefreshIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>}/>
                        </DropdownItem>
                        <DropdownItem link={'/workspaces/' + props.workspaceId + '/tags'}
                                      className={props.workspaceRole !== 'viewer' ? '' : 'hidden'}>
                            <DropdownItemText title={'Tags'} icon={<TagIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>}/>
                        </DropdownItem>
                    </div>
                    <div className={
                        classNames(props.workspaceRole !== 'owner' ? 'hidden' : 'py-1')
                    }>
                        <DropdownItem onClick={() => props.setShowDeleteModal(true)}
                                      className={(props.workspaceRole !== 'owner' ? 'hidden' : '') + ' cursor-pointer text-red-700 hover:text-red-800'}>
                            <DropdownItemText title={'Delete'} icon={<TrashIcon
                                className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500 "
                                aria-hidden="true"/>} className={'text-red-700'}/>
                        </DropdownItem>
                    </div>
                </DropdownItems>
            </Dropdown>
        </>
    )
}

export default WorkspaceMenuActions;
