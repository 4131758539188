import {Dialog} from '@headlessui/react'
import {CheckIcon} from "@heroicons/react/solid";
import BasicButton from "../UI/Buttons/BasicButton";
import Modal from "../UI/Modal/Modal";

const WorkspaceRefreshModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} onClose={props.setOpen}>
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Refresh successful
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Your workspace has been scheduled for refresh. New assets should be coming soon.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <BasicButton title={'Go back to workspace'} type={'primary'} onClick={() => props.setOpen(false)}/>
            </div>
        </Modal>
    )
}

export default WorkspaceRefreshModal;
