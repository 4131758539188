class SalieriError extends Error {
    constructor(code, errors) {
        super();

        this.code = code;
        this.errors = errors;
    }
}

export function getWorkspaces(token, teamId) {
    let resource = '/workspaces';

    if (teamId !== '') {
        resource += '?team_id=' + teamId;
    }

    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function createWorkspace(token, workspace) {
    const resource = '/workspaces';
    return queryApi('POST', resource, token, workspace)
        .then(handleErrors)
        .then((response) => response.json());
}

export function updateWorkspace(token, workspaceId, workspace) {
    const resource = '/workspaces/' + workspaceId;
    return queryApi('PUT', resource, token, workspace)
        .then(handleErrors)
        .then((response) => response.json());
}

export function deleteWorkspace(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId;
    return queryApi('DELETE', resource, token)
        .then(handleErrors);
}

export function getWorkspace(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId;
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function getTeamMembers(token, teamId) {
    const resource = '/teams/' + teamId + '/members';
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function getWorkspaceMembers(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId + '/members';
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function addWorkspaceMember(token, workspaceId, member) {
    const resource = '/workspaces/' + workspaceId + '/members';
    return queryApi('POST', resource, token, member)
        .then(handleErrors)
        .then((response) => response.json());
}

export function setWorkspaceMembers(token, workspaceId, members) {
    const resource = '/workspaces/' + workspaceId + '/members';
    return queryApi('PUT', resource, token, members)
        .then(handleErrors)
        .then((response) => response.json());
}

export function getWorkspaceThreats(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId + '/threats';
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function getWorkspaceAssets(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId + '/assets';
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function getWorkspaceAsset(token, workspaceId, assetType, assetId) {
    const resource = '/workspaces/' + workspaceId + '/assets/' + assetType + '/' + assetId;
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function muteAssetThreat(token, workspaceId, assetType, assetId, threatId) {
    const resource = '/workspaces/' + workspaceId + '/assets/' + assetType + '/' + assetId + '/threats/' + threatId + '/mute';
    return queryApi('PUT', resource, token)
        .then(handleErrors);
}

export function unmuteAssetThreat(token, workspaceId, assetType, assetId, threatId) {
    const resource = '/workspaces/' + workspaceId + '/assets/' + assetType + '/' + assetId + '/threats/' + threatId + '/un-mute';
    return queryApi('PUT', resource, token)
        .then(handleErrors);
}

export function getWorkspaceStatistics(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId + '/statistics';
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function getWorkspaceTags(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId + '/tags';
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function createWorkspaceTag(token, workspaceId, tag) {
    const resource = '/workspaces/' + workspaceId + '/tags';
    return queryApi('POST', resource, token, tag)
        .then(handleErrors)
        .then((response) => response.json());
}

export function updateWorkspaceTag(token, workspaceId, tagId, tag) {
    const resource = '/workspaces/' + workspaceId + '/tags/' + tagId;
    return queryApi('PUT', resource, token, tag)
        .then(handleErrors)
        .then((response) => response.json());
}

export function deleteWorkspaceTag(token, workspaceId, tagId) {
    const resource = '/workspaces/' + workspaceId + '/tags/' + tagId;
    return queryApi('DELETE', resource, token)
        .then(handleErrors);
}


export function updateUser(token, userId, user) {
    const resource = '/user/update-settings';
    return queryApi('PUT', resource, token, user)
        .then(handleErrors)
        .then((response) => response.json());
}

export function updateUserPassword(token, oldPassword, newPassword) {
    const resource = '/user/change-password';
    return queryApi('PUT', resource, token, {
        'old_password': oldPassword,
        'new_password': newPassword
    }).then(handleErrors).then((response) => response.json());
}

export function commentAsset(token, workspaceId, assetType, assetId, comment) {
    const resource = '/workspaces/' + workspaceId + '/assets/' + assetType + '/' + assetId + '/comments';
    return queryApi('POST', resource, token, {
        'comment': comment,
    }).then(handleErrors);
}

export function search(token, term, teamId) {
    const resource = '/search';
    return queryApi('POST', resource, token, {
        'term': term, 'team_id': teamId
    }).then(handleErrors).then((response) => response.json());
}

export function refreshWorkspace(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId + '/refresh';
    return queryApi('PUT', resource, token).then(handleErrors);
}

export function getAssetRevision(token, workspaceId, assetType, assetId, revisionId) {
    const resource = '/workspaces/' + workspaceId + '/assets/' + assetType + '/' + assetId + '/revisions/' + revisionId;
    return queryApi('GET', resource, token).then(handleErrors).then((response) => response.json());
}

export function validateAsset(token, assets) {
    const resource = '/workspaces/validate-assets';
    return queryApi('PUT', resource, token, assets).then(handleErrors).then((response) => response.json());
}

export function getWorkspaceAssetMap(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId + '/asset-map';
    return queryApi('GET', resource, token).then(handleErrors).then((response) => response.json());
}

export function editAssetDisplayName(token, workspaceId, assetType, assetId, displayName) {
    const resource = '/workspaces/' + workspaceId + '/assets/' + assetType + '/' + assetId + '/display-name';
    return queryApi('PUT', resource, token, displayName).then(handleErrors);
}

export function editAssetSecureStatus(token, workspaceId, assetType, assetId, isSecured) {
    const resource = '/workspaces/' + workspaceId + '/assets/' + assetType + '/' + assetId + '/secure-status';
    return queryApi('PUT', resource, token, isSecured).then(handleErrors);
}

export function setAssetTags(token, workspaceId, assetType, assetId, tags) {
    const resource = '/workspaces/' + workspaceId + '/assets/' + assetType + '/' + assetId + '/tags';
    return queryApi('PUT', resource, token, tags).then(handleErrors);
}

export function refreshAsset(token, workspaceId, assetType, assetId) {
    const resource = '/workspaces/' + workspaceId + '/assets/' + assetType + '/' + assetId + '/refresh';
    return queryApi('PUT', resource, token).then(handleErrors);
}

export function getWorkspaceReports(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId + '/reports';
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function viewWorkspaceReport(token, workspaceId, reportId) {
    const resource = '/workspaces/' + workspaceId + '/reports/' + reportId + '/html';
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.text());
}

export function getWorkspaceActivities(token, workspaceId) {
    const resource = '/workspaces/' + workspaceId + '/activities';
    return queryApi('GET', resource, token)
        .then(handleErrors)
        .then((response) => response.json());
}

export function getUser2faContext(token) {
    const resource = '/user/2fa-context';
    return queryApi('GET', resource, token).then(handleErrors).then((res) => res.json());
}

export function setUser2faContext(token, context) {
    const resource = '/user/2fa-context';
    return queryApi('PUT', resource, token, context).then(handleErrors);
}

export function getTeams(token) {
    const resource = '/teams';
    return queryApi('GET', resource, token).then(handleErrors).then((res) => res.json());
}

export function getTeam(token, teamId) {
    const resource = '/teams/' + teamId;
    return queryApi('GET', resource, token).then(handleErrors).then((res) => res.json());
}

export function createInvitation(token, teamId, invitation) {
    const resource = '/teams/' + teamId + '/invitations';
    return queryApi('POST', resource, token, invitation).then(handleErrors);
}

export function deleteInvitation(token, teamId, invitationId) {
    const resource = '/teams/' + teamId + '/invitations/' + invitationId;
    return queryApi('DELETE', resource, token).then(handleErrors);
}

export function getInvitation(token, teamId, invitationHash) {
    const resource = '/teams/' + teamId + '/invitations/' + invitationHash;
    return queryApi('GET', resource, token).then(handleErrors).then((res) => res.json());
}

export function acceptInvitation(token, teamId, invitationHash) {
    const resource = '/teams/' + teamId + '/invitations/' + invitationHash + '/accept';
    return queryApi('PUT', resource, token).then(handleErrors);
}

export function login(email, password, twoFactorCode) {
    const body = {
        email: email, password: password,
    };

    if (twoFactorCode !== '') {
        body['totp_code'] = twoFactorCode;
    }

    let resource = '/user/authenticate';
    return queryApi('PUT', resource, null, body).then((response) => response.json());
}

export function register(name, email, password, invitationHash) {
    const body = {
        name: name,
        email: email,
        password: password,
        invitation_hash: invitationHash,
    };

    let resource = '/user/register';
    return queryApi('PUT', resource, null, body);
}

export function handleDisconnect(error, appContext) {
    if (error.code === 'INVALID_TOKEN') {
        appContext.logout();
    }

    return error;
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.status);
    }

    return response;
}

function queryApi(method, resource, token = null, body = null) {
    let init = {
        method: method, headers: {
            'Content-Type': 'application/json',
        },
    };

    if (token !== null) {
        init.headers['Authorization'] = 'Bearer ' + token;
    }

    if (body !== null) {
        init.body = JSON.stringify(body);
    }

    return fetch(window.REACT_APP_API_URL + resource, init).then((response) => {
        if (!response.ok) {
            return response.json().then((res) => {
                throw new SalieriError(res.code, res.errors);
            });
        }

        return response;
    });
}
