import CardHeader from "./Card/CardHeader";
import Card from "./Card/Card";

const DescriptionList = (props) => {
    return (
        <Card className={props.className}>
            <CardHeader title={props.title ?? ''}/>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0 h-full">
                <dl className="sm:divide-y sm:divide-gray-200">
                    {
                        Object.keys(props.value).map((key) =>
                            <div key={key} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500 capitalize">
                                    {key}
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {props.value[key]}
                                </dd>
                            </div>
                        )
                    }
                </dl>
            </div>
        </Card>
    )
}

export default DescriptionList;