import React from 'react';

function InputTextarea(props) {
    return (
        <textarea name={props.name} id={props.id} rows={props.rows ?? 3}
               className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm " + (props.className ?? '')}
               placeholder={props.placeholder} value={props.value}
               onChange={props.onChange}>
        </textarea>
    );
}

export default InputTextarea;
