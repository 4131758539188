import React from 'react';

function InputRadio(props) {
    return (
        <input type="radio" name={props.name} id={props.id}
               checked={props.checked}
               style={props.style}
               className={"h-6 w-6 cursor-pointer border-0 focus:outline-none focus:ring-0 focus:ring-offset-0 " + (props.className ?? '')}
               onChange={props.onChange}/>
    );
}

export default InputRadio;
