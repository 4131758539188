import AssetTableItem from "./AssetTableItem";
import Table from "../../Table/Table";

const AssetTable = (props) => {
    const assets = (props.assets ?? []).map((asset) => {
        return {
            tableKey: asset.id,
            onSelectAsset: props.onSelectAsset,
            isWorkspaceSecured: props.isWorkspaceSecured,
            ...asset
        };
    });

    return (
        <Table className={props.className}
               title={'Assets'}
               keys={['Type', 'Name', 'First seen', 'Last seen', 'Is secured']}
               items={assets}
               tableItem={AssetTableItem}
               usePagination={props.usePagination}
               paginationPage={props.paginationPage}
               paginationSize={props.paginationSize}/>
    )
}

export default AssetTable;