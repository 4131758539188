import Page from "../../components/Page/Page";
import PageHeader from "../../components/Page/PageHeader";
import PageTitle from "../../components/Page/PageTitle";
import {useContext, useState} from "react";
import AppContext from "../../store/app-context";
import {createWorkspace, handleDisconnect, validateAsset} from "../../requests";
import {useNavigate} from "react-router-dom";
import WorkspaceEdit from "../../components/Workspace/WorkspaceEdit";
import Alert from "../../components/Alert/Alert";

export default function WorkspacesCreate() {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();

    const pages = [{name: 'Workspaces', href: '/workspaces', current: true}];

    const [errorMessages, setErrorMessages] = useState([]);

    const onCreateWorkspace = (workspace) => {
        setErrorMessages([]);

        validateAsset(appContext.token, workspace.assets).then((errors) => {
            if (errors.length > 0) {
                setErrorMessages(errors);
                return;
            }

            createWorkspace(appContext.token, workspace)
                .then((workspace) => {
                    navigate('/workspaces/' + workspace.id);
                })
                .catch((error) => handleDisconnect(error, appContext));
        }).catch((error) => handleDisconnect(error, appContext));
    };

    return (<Page>
        <PageHeader pages={pages}>
            <PageTitle title='Create new workspace'/>
        </PageHeader>
        <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
                <Alert className={'mb-8'} type={'danger'} title={'Invalid assets'} messages={errorMessages}
                       show={errorMessages.length > 0}/>
                <WorkspaceEdit team_id={appContext.currentTeam.id}
                               onSubmit={onCreateWorkspace}
                               canCreateSecured={appContext.user.features.includes('SECURED_WORKSPACE')}/>
            </div>
        </div>
    </Page>)
}