import React, {useEffect, useState} from 'react';
import BasicButton from "../UI/Buttons/BasicButton";
import Modal from "../UI/Modal/Modal";
import InputLabel from "../UI/Inputs/InputLabel";
import InputText from "../UI/Inputs/InputText";
import InputRadio from "../UI/Inputs/InputRadio";
import Tag from "./Tag";

function TagEditModal(props) {
    const [name, setName] = useState('');
    const [group, setGroup] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('');

    useEffect(() => {
        setName(props.tag.name ?? '');
        setGroup(props.tag.group ?? '');
        setDescription(props.tag.description ?? '');
        setColor(props.tag.color ?? '#4f46e5');
    }, [props.isOpen, props.tag])

    function save() {
        if (!name) {
            return;
        }

        const updatedTag = {
            id: props.tag.id,
            name: name,
            group: group,
            description: description,
            color: color,
        }

        props.onEditTag(updatedTag);
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <div className="flex flex-row justify-between">
                    <h3 className="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title">{props.tag.id ? 'Edit tag' : 'New Tag'}</h3>
                    <Tag group={group} name={name} classes={'text-white'} color={color}/>
                </div>
                <div className="flex flex-col gap-3 mt-4">
                    <div>
                        <InputLabel htmlFor="name" title={'Name'}/>
                        <InputText name="name" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div>
                        <InputLabel htmlFor="group" title={'Group'}/>
                        <InputText name="group" id="group" value={group} onChange={(e) => setGroup(e.target.value)}/>
                    </div>
                    <div>
                        <InputLabel htmlFor="description" title={'Description'}/>
                        <InputText name="description" id="description" value={description}
                                   onChange={(e) => setDescription(e.target.value)}/>
                    </div>
                    <div>
                        <InputLabel htmlFor="color" title={'Color'}/>
                        <InputText name="color" id="color" value={color}
                                   onChange={(e) => setColor(e.target.value)}/>
                        <div className="flex flex-row gap-2 mt-3">
                            <InputRadio name="color" checked={'#4f46e5' === color}
                                        style={{backgroundColor: '#4f46e5', color: '#4f46e5'}}
                                        onChange={() => setColor('#4f46e5')}/>
                            <InputRadio name="color" checked={'#ec4899' === color}
                                        style={{backgroundColor: '#ec4899', color: '#ec4899'}}
                                        onChange={() => setColor('#ec4899')}/>
                            <InputRadio name="color" checked={'#a855f7' === color}
                                        style={{backgroundColor: '#a855f7', color: '#a855f7'}}
                                        onChange={() => setColor('#a855f7')}/>
                            <InputRadio name="color" checked={'#3b82f6' === color}
                                        style={{backgroundColor: '#3b82f6', color: '#3b82f6'}}
                                        onChange={() => setColor('#3b82f6')}/>
                            <InputRadio name="color" checked={'#28b457' === color}
                                        style={{backgroundColor: '#28b457', color: '#28b457'}}
                                        onChange={() => setColor('#28b457')}/>
                            <InputRadio name="color" checked={'#eab308' === color}
                                        style={{backgroundColor: '#eab308', color: '#eab308'}}
                                        onChange={() => setColor('#eab308')}/>
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <BasicButton type={'secondary'} title={'Cancel'} className={'justify-center'}
                                 onClick={() => props.onClose()}/>
                    <BasicButton type={'primary'} title={'Save'} className={'justify-center'} onClick={() => save()}/>
                </div>
            </div>
        </Modal>
    )
}

export default TagEditModal;
