import React from 'react';
import {Menu} from "@headlessui/react";
import {Link} from "react-router-dom";

function DropdownItem(props) {

    return (
        <Menu.Item key={props.id}>
            {({active}) => (
                props.link ?
                    <Link to={props.link}
                          className={(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700') + ' group flex items-center px-4 py-2 text-sm z-10 relative ' + (props.className ?? '')}>
                        {props.children}
                    </Link>
                    :
                    <span
                        className={(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700') + ' group flex items-center px-4 py-2 text-sm z-10 relative ' + (props.className ?? '')}
                        onClick={props.onClick}>
                    {props.children}
                    </span>
            )}
        </Menu.Item>
    );
}

export default DropdownItem;
