import {Link} from "react-router-dom";
import {ExclamationIcon} from "@heroicons/react/outline";
import Badge from "../Badge";
import {ShieldCheckIcon} from "@heroicons/react/solid";

const levelWeights = {
    high: 3,
    medium: 2,
    low: 1
};

const sortThreats = (a, b) => {
    const aWeight = levelWeights[a[0]];
    const bWeight = levelWeights[b[0]];

    return bWeight - aWeight;
}

const getThreatClasses = (level) => {
    switch (level) {
        case 'high':
            return 'bg-red-200 text-red-600';
        case 'medium':
            return 'bg-orange-200 text-orange-600';
        case 'low':
        default:
            return 'bg-blue-200 text-blue-600';
    }
}

const WorkspaceCard = (props) => {
    const Icon = <ExclamationIcon className={'h-5 w-5'}/>

    return (
        <Link to={'/workspaces/' + props.id} key={props.id}
              className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                    <div className="flex justify-between space-x-3">
                        <h3 className={'flex gap-1 items-center text-md font-medium truncate ' + (props.is_secured ? 'text-green-500' : 'text-gray-900')}>
                            {props.is_secured ? <ShieldCheckIcon className={'h-4 w-4'}/> : null}
                            {props.name}
                        </h3>
                        <span
                            className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs font-medium bg-blue-100 rounded-full capitalize">
                            {props.role}
                        </span>
                    </div>
                    <p className="mt-1 text-gray-500 text-sm truncate">
                        {props.description}
                    </p>
                    {Object.keys(props.threats ?? {}).length > 0 ? (
                        <div className={'flex mt-3 gap-2'}>
                            {Object.entries(props.threats).sort(sortThreats).map(([threat, count]) => <Badge
                                key={threat} title={count}
                                classes={getThreatClasses(threat)}
                                icon={Icon}/>)}
                        </div>
                    ) : null}
                </div>
            </div>
        </Link>
    )
}

export default WorkspaceCard;