import {useNavigate} from "react-router-dom";
import {ShieldCheckIcon} from "@heroicons/react/solid";

const getThreatClasses = (level) => {
    switch (level) {
        case 'high':
            return 'text-red-600';
        case 'medium':
            return 'text-orange-600';
        case 'low':
        default:
            return 'text-blue-600';
    }
}

const WorkspaceTableItem = (props) => {
    const navigate = useNavigate();

    return (
        <tr onClick={() => navigate('/workspaces/' + props.id)} className='hover:cursor-pointer'>
            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm">
                <div className="flex items-center gap-2">
                    <div className="h-10 w-10 flex-shrink-0">
                        <span className="h-10 w-10 rounded-full flex items-center justify-center bg-gray-100 uppercase">
                            {props.name.slice(0, 2)}
                        </span>
                    </div>
                    <div className="ml-4">
                        <div className="font-medium text-gray-900 flex items-center gap-1">
                            {props.is_secured ? <ShieldCheckIcon className={'h-5 w-5 text-green-500'}/> : null}
                            {props.name}
                        </div>
                        <div className="text-gray-500">{props.description}</div>
                    </div>
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                {props.refresh_interval}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
                {props.role}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize font-bold">
                {props.threats.low ? <span className={getThreatClasses('low')}>{props.threats.low}</span> : null}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize font-bold">
                {props.threats.medium ? <span className={getThreatClasses('medium')}>{props.threats.medium}</span> : null}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize font-bold">
                {props.threats.high ? <span className={getThreatClasses('high')}>{props.threats.high}</span> : null}
            </td>
        </tr>
    )
}

export default WorkspaceTableItem;