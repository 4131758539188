import DescriptionList from "../../DescriptionList";

const Pop3ServiceMetadata = (props) => {
    const description = {
        'banner': props.metadata.banner,
    };

    return <DescriptionList className={props.className} title={'Service Metadata'} value={description}/>
}

export default Pop3ServiceMetadata;