import {ShieldCheckIcon} from "@heroicons/react/solid";
import H2 from "../UI/Texts/H2";

const WorkspacePageTitle = (props) => {
    return (
        <div className="flex-1 min-w-0">
            <div className='flex items-center gap-2'>
                {props.isSecured ? <ShieldCheckIcon className={'h-7 w-7 text-green-500'}/> : null}
                <H2 title={props.title} className={props.isSecured ? 'text-green-500' : 'text-gray-900'} />
            </div>
            {props.children}
        </div>
    )
}

export default WorkspacePageTitle;
