import H2 from "../UI/Texts/H2";

const PageTitle = (props) => {
    return (
        <div className="flex-1 min-w-0">
            <H2>
                {props.title}
            </H2>
            {props.children}
        </div>
    )
}

export default PageTitle;
