import Badge from "../Badge";
import React from "react";
import BasicButton from "../UI/Buttons/BasicButton";

const TeamInvitationTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 underline text-primary">
                {props.email}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                <Badge title={props.role} classes={'bg-primary text-white'}/>
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                <time dateTime={new Date(props.created_at).toLocaleDateString('en-CA')}>
                    {new Date(props.created_at).toLocaleDateString('en-CA')}
                </time>
            </td>
            <td className={'whitespace-nowrap py-4 px-3 flex'}>
                <BasicButton title={'Cancel invitation'} type={'actionDelete'} onClick={() => props.onCancelInvitation(props.id)}/>
            </td>
        </tr>
    )
}

export default TeamInvitationTableItem;
