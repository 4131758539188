import {PencilIcon} from "@heroicons/react/solid";
import BasicButton from "../../UI/Buttons/BasicButton";
import Tag from "../Tag";

const TagTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <Tag group={props.group} name={props.name} classes={'text-white'} color={props.color}/>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <span className={'text-sm text-gray-400'}>{props.description}</span>
            </td>
            <td className="-ml-0.5 whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-end">
                {props.showEdit ? (
                    <BasicButton title={'Edit'} icon={<PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>}
                                 onClick={() => props.onEditTag(props)} type={'action'}/>
                ) : null}
                {props.showDelete ? (
                    <BasicButton title={'Delete'}
                                 onClick={() => props.onDeleteTag(props)} type={'actionDelete'}/>

                ) : null}
            </td>
        </tr>
    )
}

export default TagTableItem;
