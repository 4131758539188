import Card from "../../Card/Card";
import {AtSymbolIcon, ClipboardListIcon, CodeIcon, CubeIcon, GlobeIcon, ServerIcon} from "@heroicons/react/outline";
import AssetListItem from "./AssetListItem";
import EmptyAssetState from "../EmptyAssetState";
import {GlobeAltIcon} from "@heroicons/react/solid";
import LoadingAssetState from "../LoadingAssetState";
import React from "react";
import AssetListFilters from "./AssetListFilters";
import {filterAssets} from "../AssetFilter/asset-filter";
import ListCardHeader from "../../List/ListCardHeader";

function getIcon(type) {
    switch (type) {
        case 'domain':
            return GlobeIcon;
        case 'git':
            return CodeIcon;
        case 'host':
            return ServerIcon;
        case 'certificate':
            return ClipboardListIcon;
        case 'website':
            return GlobeAltIcon
        case 'email':
            return AtSymbolIcon;
        default:
            return CubeIcon;
    }
}

function getLink(asset) {
    switch (asset.type) {
        case 'domain':
            return '/domains/' + asset.id;
        case 'host':
            return '/hosts/' + asset.id;
        case 'service':
            return '/services/' + asset.id;
        case 'certificate':
            return '/certificates/' + asset.id;
        case 'website':
            return '/websites/' + asset.id;
        case 'email':
            return '/emails/' + asset.id;
        default:
            return '#';
    }
}

const assetWeights = {
    'domain': 6,
    'host': 5,
    'service': 4,
    'email': 3,
    'website': 2,
    'record': 1,
    'certificate': 0,
};

const sortAssets = (a, b) => {
    const aWeight = assetWeights[a['type']];
    const bWeight = assetWeights[b['type']];

    return (b['is_secured'] ? 1 : 0) - (a['is_secured'] ? 1 : 0) || bWeight - aWeight;
}

const AssetList = (props) => {
    const filteredAssets = (
        props.assetFilters !== undefined ? filterAssets(props.assets, props.assetFilters) : props.assets
    ).sort(sortAssets);

    return (
        <Card className={props.className ?? ''}>
            <ListCardHeader title={'Assets'} count={filteredAssets.length}>
                {props.assetFilters ? (
                    <AssetListFilters assetFilters={props.assetFilters}
                                      setAssetFilters={props.setAssetFilters}/>
                ) : null}
            </ListCardHeader>
            {
                props.isLoading ? (
                    <LoadingAssetState/>
                ) : (
                    filteredAssets.length === 0 ? <EmptyAssetState/> :
                        <ul className="divide-y divide-gray-200 overflow-x-auto">
                            {filteredAssets.map((asset) => (
                                <AssetListItem key={asset.id} icon={getIcon(asset.type)} {...asset}
                                               link={'/workspaces/' + props.workspaceId + getLink(asset)}/>
                            ))}
                        </ul>
                )
            }
        </Card>
    )
}

export default AssetList;
