import {Dialog} from '@headlessui/react'
import {ExclamationIcon, XIcon} from '@heroicons/react/outline'
import BasicButton from "../UI/Buttons/BasicButton";
import Modal from "../UI/Modal/Modal";

const TagDeleteModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <BasicButton type={'noBorder'} icon={<XIcon className="h-6 w-6" aria-hidden="true"/>} onClick={() => props.onClose()} />
            </div>
            <div className="sm:flex sm:items-start">
                <div
                    className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Delete tag {props.tag.name}
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Are you sure you want to delete your tag? This action cannot be undone.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-2">
                <BasicButton type={'danger'} title={'Delete'} onClick={() => props.onDeleteTag()}/>
                <BasicButton type={'secondary'} title={'Cancel'} onClick={() => props.onClose()}/>
            </div>
        </Modal>
    )
}

export default TagDeleteModal;
