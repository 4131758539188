import {TagIcon} from "@heroicons/react/outline";

const TagTableEmptyState = () => {
    return (
        <div className="h-full min-h-56 flex flex-col items-center justify-center md:pl-0 md:pr-0 pl-3 pr-3">
            <TagIcon className="h-12 w-12 text-gray-500"/>
            <h3 className="mt-2 text-md font-medium text-gray-900">No tags</h3>
            <p className="mt-1 text-sm text-gray-500 text-center">
                No tags on the workspace. Create a new one!
            </p>
        </div>
    )
}

export default TagTableEmptyState;
