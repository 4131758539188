import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import TimelineItem from "./TimelineItem";
import CardHeader from "../Card/CardHeader";

const Timeline = (props) => {
    return (
        <div>
            <Card className={props.className}>
                <CardHeader title={'Activities'}/>
                <CardBody>
                    <div className="flow-root">
                        <ul className='flex flex-col gap-6'>
                            {props.activities.map((activityItem, activityItemIdx) => (
                                <li key={activityItemIdx}>
                                    <div className="relative">
                                        {activityItemIdx !== props.activities.length - 1 ? (
                                            <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                                  aria-hidden="true"/>
                                        ) : null}
                                        <div className="relative flex items-start space-x-3">
                                            <TimelineItem activity={activityItem} onClick={props.onClick}/>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default Timeline;