import {ChevronRightIcon, ShieldCheckIcon, ShieldExclamationIcon} from "@heroicons/react/solid";
import {ArrowRightIcon, CalendarIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import Tag from "../../Tag/Tag";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function getTextClasses(asset) {
    if (!asset.is_active) {
        return 'text-gray-500 line-through';
    }

    const threatsBySeverity = asset.threats.reduce((acc, cur) => {
        if (!(cur.level in acc)) {
            acc[cur.level] = 0;
        }

        acc[cur.level] = acc[cur.level] + 1;

        return acc;
    }, {});

    if ('high' in threatsBySeverity) {
        return 'text-red-500';
    }

    if ('medium' in threatsBySeverity) {
        return 'text-orange-500';
    }

    if ('low' in threatsBySeverity) {
        return 'text-blue-500';
    }

    return asset.is_secured ? 'text-green-500' : 'text-primary';
}

const AssetListItem = (props) => {
    const Icon = props.threats.length > 0 ? ShieldExclamationIcon : ShieldCheckIcon;

    return (
        <Link to={props.link} className="grid grid-cols-12 justify-between hover:bg-gray-50 px-4 py-4 gap-2">
            <div className='md:col-span-5 col-span-10 flex gap-4 items-center'>
                <props.icon className={
                    classNames(
                        "h-5 w-5",
                        getTextClasses(props)
                    )
                } aria-hidden="true"/>
                <div className='flex flex-col justify-center overflow-hidden'>
                    <p className={
                        classNames(
                            "text-sm font-medium truncate flex items-center gap-1",
                            getTextClasses(props),
                        )
                    }>
                        {props.is_secured ? <Icon className={'h-5 w-5'}/> : null}
                        {props.display_name ?? props.name}
                    </p>
                    <p className="text-sm font-medium text-gray-500 truncate">
                        {props.description}
                    </p>
                </div>
            </div>
            <div className='col-span-5 flex flex-col justify-center md:block'>
                <div className='flex text-sm text-gray-500 gap-1'>
                    <CalendarIcon className="mr-1 h-5 w-5" aria-hidden="true"/>
                    <time dateTime={new Date(props.first_seen).toLocaleDateString('en-CA')}>
                        {new Date(props.first_seen).toLocaleDateString('en-CA')}
                    </time>
                    <ArrowRightIcon className="mr-1 h-5 w-5" aria-hidden="true"/>
                    <time dateTime={new Date(props.last_seen).toLocaleDateString('en-CA')}>
                        {new Date(props.last_seen).toLocaleDateString('en-CA')}
                    </time>
                </div>

                <p className="mt-2 flex items-center gap-1 text-sm text-gray-500 flex-wrap">
                    {props.tags.map((tag) =>
                        <Tag key={tag.id} group={tag.group} name={tag.name} classes={'text-white'} color={tag.color}/>
                    )}
                </p>
            </div>
            <div className='col-span-2 flex gap-4 items-center justify-end'>
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
            </div>
        </Link>
    )
}

export default AssetListItem;
