import Badge from "../Badge";
import {VolumeOffIcon, VolumeUpIcon} from "@heroicons/react/outline";
import BasicButton from "../UI/Buttons/BasicButton";

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

const getSpanClasses = (threat) => {
    if (threat.muted ?? false) {
        return 'bg-gray-100 text-gray-400';
    }

    switch (threat.level) {
        case "high":
            return 'bg-red-300 text-red-600';
        case "medium":
            return 'bg-orange-300 text-orange-600';
        case "low":
        default:
            return 'bg-blue-300 text-blue-600';
    }
}

const getSvgClasses = (threat) => {
    if (threat.muted ?? false) {
        return 'text-gray-400';
    }

    switch (threat.level) {
        case "high":
            return 'text-red-600';
        case "medium":
            return 'text-orange-600';
        case "low":
        default:
            return 'text-blue-600';
    }
};

const ThreatTableItem = (props) => {
    return (
        <tr>
            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-gray-900 sm:pl-6'>
                <span className={classNames(getSpanClasses(props),
                    'inline-flex items-center px-3 py-0.5 rounded-full md:text-sm text-xs md:font-medium'
                )}>
                    <svg className={classNames(getSvgClasses(props),
                        '-ml-1 mr-1.5 h-2 w-2'
                    )} fill="currentColor" viewBox="0 0 8 8">
                        <circle cx={4} cy={4} r={3}/>
                    </svg>
                    {props.level[0].toUpperCase() + props.level.slice(1)}
            </span>
            </td>
            <td>
                <span className={'mr-2 ' + ((props.muted ?? false) ? 'text-gray-400 line-through' : '')}>
                    {props.title ?? props.description}
                </span>
                <Badge title={(props.assets ?? [0]).length} classes={getSpanClasses(props)}/>
            </td>
            <td>
                {props.onMuteThreat && !(props.muted ?? false) ? (
                    <BasicButton title={'Mute threat'} icon={ <VolumeOffIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />} type={'action'} onClick={() => props.onMuteThreat(props.id)} />
                ) : null}
                {props.onUnMuteThreat && (props.muted ?? false) ? (
                    <BasicButton title={'Unmute threat'} icon={<VolumeUpIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />} type={'action'} onClick={() => props.onUnMuteThreat(props.id)} />
                ) : null}
            </td>
        </tr>
    )
}

export default ThreatTableItem;
