import {HomeIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";

const Breadcrumbs = (props) => {
    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
                <li>
                    <div>
                        <Link to="/" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                {(props.pages ?? []).map((page) => (
                    <li key={page.id}>
                        <div className="flex items-center">
                            <svg className="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            <Link to={page.href} className="md:ml-4 ml-1 md:text-sm md:font-medium text-xs text-gray-500 hover:text-gray-700"
                               aria-current={page.current ? 'page' : undefined}>
                                {page.name}
                            </Link>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export default Breadcrumbs;
