import {CalendarIcon, EyeIcon} from "@heroicons/react/outline";
import Badge from "../Badge";
import BasicButton from "../UI/Buttons/BasicButton";
import React from "react";

const ReportTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                <Badge title={props.type} classes={'bg-primary text-white'}/>
            </td>
            <td className="whitespace-nowrap px-3 py-4 flex flex-col text-sm text-gray-500 gap-1">
                <div className={'flex gap-1'}>
                    <CalendarIcon className="mr-1 h-5 w-5" aria-hidden="true"/>
                    <time dateTime={new Date(props.started_at).toLocaleString('en-CA')}>
                        {new Date(props.started_at).toLocaleString('en-CA')}
                    </time>
                </div>
                <div className={'flex gap-1'}>
                    <CalendarIcon className="mr-1 h-5 w-5" aria-hidden="true"/>
                    <time dateTime={new Date(props.ended_at).toLocaleString('en-CA')}>
                        {new Date(props.ended_at).toLocaleString('en-CA')}
                    </time>
                </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.processed_at !== null ? new Date(props.processed_at).toLocaleString('en-CA') : 'None'}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.sent_at !== null ? new Date(props.sent_at).toLocaleString('en-CA') : 'None'}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.items}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.processed_at !== null ? (
                    <BasicButton title={'View'} icon={<EyeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>}
                                 onClick={() => props.onViewReport(props)} type={'action'}/>
                ) : null}
            </td>
        </tr>
    )
}

export default ReportTableItem;
