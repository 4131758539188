import AppContext from "../../../store/app-context";
import DescriptionList from "../../../components/DescriptionList";
import RevisionModal from "../../../components/Timeline/RevisionModal";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    commentAsset,
    editAssetDisplayName,
    editAssetSecureStatus,
    getAssetRevision,
    getWorkspace,
    getWorkspaceAsset,
    getWorkspaceTags,
    handleDisconnect,
    muteAssetThreat,
    setAssetTags,
    unmuteAssetThreat
} from "../../../requests";
import PageHeader from "../../../components/Page/PageHeader";
import Page from "../../../components/Page/Page";
import ThreatTable from "../../../components/Threat/ThreatTable";
import Timeline from "../../../components/Timeline/Timeline";
import Comment from "../../../components/Comment/Comment";
import Alert from "../../../components/Alert/Alert";
import AssetPageMenu from "./AssetPageMenu";
import AssetPageTitle from "./AssetPageTitle";
import TagSelectModal from "../../Tag/TagSelectModal";
import {RefreshIcon} from "@heroicons/react/outline";
import Tag from "../../Tag/Tag";

const AssetPage = (props) => {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();

    const [pages, setPages] = useState([]);
    const [threats, setThreats] = useState([]);
    const [activities, setActivities] = useState([]);
    const [workspaceTags, setWorkspaceTags] = useState([]);
    const [asset, setAsset] = useState(props.initialAsset);
    const [revision, setRevision] = useState({});
    const [workspace, setWorkspace] = useState({
        'role': ''
    });

    const [showSelectTagModal, setShowSelectTagModal] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    // Some overrides
    const TopSide = props.topSide;
    const LeftSide = props.leftSide;

    // Pull props
    const {workspaceId, assetType, assetId, onUpdateAsset, assetDisplayFn} = props;

    const onEditAssetName = (newAssetName) => {
        editAssetDisplayName(appContext.token, workspaceId, assetType, assetId, newAssetName).then(() => {
            navigate(0);
        });
    }

    const onLeaveComment = (comment) => {
        if (comment !== '') {
            commentAsset(appContext.token, workspaceId, assetType, assetId, comment).then(() => {
                setTimeout(() => {
                    navigate(0);
                }, 1000);
            });
        }
    }

    const onApplyTags = (tags) => {
        setAssetTags(appContext.token, workspaceId, assetType, assetId, tags).then(() => navigate(0));
    }

    const onTimelineItemClick = (activity) => {
        getAssetRevision(appContext.token, workspaceId, assetType, assetId, activity.revision)
            .then(setRevision);
    }

    const updateAssetSecureStatus = (status) => {
        editAssetSecureStatus(appContext.token, workspaceId, assetType, assetId, status)
            .then(() => navigate(0));
    }

    const onMuteThreat = (threatId) => {
        muteAssetThreat(appContext.token, workspaceId, assetType, assetId, threatId)
            .then(() => navigate(0));
    }

    const onUnMuteThreat = (threatId) => {
        unmuteAssetThreat(appContext.token, workspaceId, assetType, assetId, threatId)
            .then(() => navigate(0));
    }

    useEffect(() => {
        setPages([
            {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
            {id: 2, name: workspaceId, href: '/workspaces/' + workspaceId, current: false},
            {id: 3, name: 'Assets', href: '#', current: false},
            {
                id: 4,
                name: asset.asset.display_name ?? (assetDisplayFn !== undefined ? assetDisplayFn(asset) : asset.asset['id']),
                href: '/workspaces/' + workspaceId + '/' + assetType + '/' + assetId,
                current: true
            },
        ]);
    }, [workspaceId, assetType, assetId, assetDisplayFn, asset]);

    useEffect(() => {
        getWorkspaceAsset(appContext.token, workspaceId, assetType, assetId)
            .then((asset) => {
                setAsset(asset);
                setActivities(asset.asset.activities);
                setThreats(asset.asset.threats);

                onUpdateAsset(asset);
                setIsLoading(false);
            })
            .catch((error) => handleDisconnect(error, appContext));

        getWorkspaceTags(appContext.token, workspaceId)
            .then(setWorkspaceTags)
            .catch((error) => handleDisconnect(error, appContext));

        getWorkspace(appContext.token, workspaceId).then(setWorkspace);

    }, [appContext, workspaceId, assetType, assetId, onUpdateAsset]);

    return (
        <Page>
            {isLoading ?
                <div className="h-full flex flex-col items-center justify-center pt-10 pb-10 md:pl-0 md:pr-0 pl-3 pr-3">
                    <RefreshIcon className="animate-reverse-spin h-12 w-12 text-gray-400"/>
                </div>
                :
                <>
                    <PageHeader pages={pages}>
                        <AssetPageTitle
                            title={asset.asset.display_name ?? (assetDisplayFn !== undefined ? assetDisplayFn(asset) : asset.asset['id'])}
                            onEdit={onEditAssetName}
                            isEditable={workspace.role === 'editor' || workspace.role === 'owner'}
                            isSecured={asset.asset.is_secured ?? false} isActive={asset.asset.is_active ?? false}
                            threats={asset.asset.threats}>
                            <div className="mt-4 flex flex-row flex-wrap gap-1">
                                {asset.asset.tags.map((tag) => <Tag key={tag.id} group={tag.group} name={tag.name}
                                                                      classes={'text-white'} color={tag.color}/>)}
                            </div>
                        </AssetPageTitle>
                        <AssetPageMenu workspaceRole={workspace.role}
                                       asset={asset}
                                       updateAssetSecureStatus={updateAssetSecureStatus}
                                       setShowSelectTagModal={setShowSelectTagModal}/>
                    </PageHeader>
                    <Alert className={'mb-8'} type={'warning'} title={'Inactive asset'}
                           messages={[
                               'Asset was last seen the ' + new Date(asset.asset.last_seen).toLocaleDateString('en-ca')
                           ]} show={!asset.asset.is_active}/>
                    <div className='grid grid-cols-12 gap-x-4 gap-y-8'>
                        {TopSide !== undefined ? <TopSide asset={asset}/> : null}
                        <div className='col-span-12 lg:col-span-5'>
                            {LeftSide !== undefined ? (
                                <LeftSide asset={asset}/>
                            ) : (
                                <DescriptionList className={'h-90'} title={'Information'} value={props.description}/>
                            )}
                        </div>
                        <div className='col-span-12 lg:col-span-7'>
                            <ThreatTable className={'h-full'}
                                         threats={threats}
                                         onMuteThreat={workspace.role !== 'viewer' ? onMuteThreat : null}
                                         onUnMuteThreat={workspace.role !== 'viewer' ? onUnMuteThreat : null}/>
                        </div>
                        {props.children}
                        <div className='col-span-12 flex flex-col gap-y-8'>
                            <Timeline className={'max-h-80 overflow-x-auto'} activities={activities}
                                      onClick={onTimelineItemClick}/>
                            <Comment onLeaveComment={onLeaveComment}/>
                        </div>
                    </div>
                    <RevisionModal isOpen={Object.keys(revision).length > 0} setOpen={() => setRevision({})}
                                   oldData={revision['previous']} newData={revision['current']}/>
                    <TagSelectModal isOpen={showSelectTagModal}
                                    setOpen={setShowSelectTagModal}
                                    workspaceTags={workspaceTags}
                                    assetTags={asset.asset.tags}
                                    onApplyTags={onApplyTags}/>
                </>}
        </Page>
    )
}

export default AssetPage;
