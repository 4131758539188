const RecordTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {props.name}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                    {props.type}
                </span>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {props.ttl}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex gap-2">
                {props.values.join(', ')}
            </td>
        </tr>
    )
}

export default RecordTableItem;