import {Navigate, Route, Routes} from "react-router-dom";
import Login from "../routes/login";
import Register from "../routes/register";
import TeamsInvitation from "../routes/teams/invitation";

const AnonymousRoutes = (props) => {
    return (
        <Routes>
            <Route path="/login" exact element={<Login login={props.login}/>}/>
            <Route path="/register" exact element={<Register/>}/>
            <Route path="/teams/:team/invitations/:invitation" exact element={<TeamsInvitation/>}/>
            <Route path="*" exact element={<Navigate replace to="/login"/>}/>
        </Routes>
    );
}

const AnonymousRouter = (props) => {
    return <AnonymousRoutes {...props}/>
}

export default AnonymousRouter;