import Table from "../Table/Table";
import ReportTableItem from "./ReportTableItem";

const ReportTable = (props) => {
    const members = (props.reports ?? []).map((report) => {
        return {
            tableKey: report.id,
            ...report
        };
    });

    const TableItem = (report) => {
        return <ReportTableItem {...report} onViewReport={props.onViewReport}/>
    }

    return (
        <Table className={props.className}
               title={'Reports'}
               keys={['Type', 'Timeframe', 'Processed At', 'Sent At', 'Items', '']}
               items={members}
               tableItem={TableItem}
               usePagination={props.usePagination ?? true}
               paginationSize={props.paginationSize ?? 7}/>
    )
}

export default ReportTable;