import DescriptionList from "../../DescriptionList";

const SshServiceMetadata = (props) => {
    const description = {
        'software': props.metadata.server_id.software,
        'version': props.metadata.server_id.version,
        'comment': props.metadata.server_id.comment,
    };

    return <DescriptionList className={props.className} title={'Service Metadata'} value={description}/>
}

export default SshServiceMetadata;