import {CogIcon, LockClosedIcon, LockOpenIcon} from "@heroicons/react/outline";
import {TagIcon} from "@heroicons/react/solid";
import React from "react";
import Dropdown from "../../UI/Dropdown/Dropdown";
import DropdownButton from "../../UI/Dropdown/DropdownButton";
import DropdownItems from "../../UI/Dropdown/DropdownItems";
import DropdownItem from "../../UI/Dropdown/DropdownItem";
import DropdownItemText from "../../UI/Dropdown/DropdownItemText";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const AssetPageMenu = (props) => {
    return (
        <Dropdown className={classNames(
            'relative inline-block text-left',
            props.workspaceRole === 'viewer' ? 'hidden' : 'none',
        )}>
            <DropdownButton title={'Actions'} icon={<CogIcon className="h-5 w-5" aria-hidden="true"/>}/>
            <DropdownItems>
                <div className={'py-1'}>
                    <DropdownItem onClick={() => props.setShowSelectTagModal(true)}
                                  className={(props.workspaceRole !== 'viewer' ? '' : '!hidden') + ' cursor-pointer'}>
                        <DropdownItemText title={'Tags'} icon={<TagIcon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>}/>
                    </DropdownItem>
                    <DropdownItem onClick={() => props.updateAssetSecureStatus(!props.asset.asset.is_secured)}
                                  className={(props.workspaceRole === 'viewer' ? 'hidden' : '') + ' cursor-pointer'}>
                        {props.asset.asset.is_secured ? (
                            <DropdownItemText title={'Unsecure asset'} icon={<LockOpenIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>}/>
                        ) : (
                            <DropdownItemText title={'Secure asset'} icon={<LockClosedIcon
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"/>}/>
                        )}
                    </DropdownItem>
                </div>
            </DropdownItems>
        </Dropdown>
    )
}

export default AssetPageMenu;
