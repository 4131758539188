import React from 'react';

function DropdownItemText(props) {
    return (
        <>
            {props.icon}
            {props.title}
        </>
    );
}

export default DropdownItemText;
