import {Fragment} from "react";
import {TagIcon} from "@heroicons/react/solid";

const TagActivity = (props) => {
    const user = props.activity.user ?? {
        'name': 'Ghost User',
        'avatar': 'https://avatars.githubusercontent.com/u/10137',
    };

    return (
        <Fragment>
            <div>
                <div className="relative px-1">
                    <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                        <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
                    </div>
                </div>
            </div>
            <div className="min-w-0 flex-1 py-0">
                <div className="text-sm leading-8 text-gray-500">
                    <span className="mr-0.5">
                        <span className="font-medium text-gray-900">
                            {user.name}
                        </span>{' '}
                        {props.activity.action ?? 'added'} tags
                    </span>{' '}
                    <span className="mr-0.5">
                        {props.activity.tags.map((tag) => (
                            <Fragment key={tag.id}>
                                <span className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span className='h-1.5 w-1.5 rounded-full' aria-hidden="true"
                                              style={{backgroundColor: tag.color}}/>
                                    </span>
                                    <span className="ml-3.5 font-medium text-gray-900">{tag.name}</span>
                                </span>{' '}
                          </Fragment>
                        ))}
                    </span>
                    <span className="whitespace-nowrap">
                        {new Date(props.activity.date).toLocaleDateString('en-ca')}
                    </span>
                </div>
            </div>
        </Fragment>
    )
}

export default TagActivity;