import WorkspaceStatistic from "../WorkspaceStatistic/WorkspaceStatistic";
import ThreatPieChart from "../../Threat/ThreatPieChart";
import ThreatTable from "../../Threat/ThreatTable";
import Alert from "../../Alert/Alert";
import Calendar from "../../Calendar/Calendar";
import AssetList from "../../Asset/AssetList/AssetList";
import React from "react";

const statistics = {
    'total_active_assets': 1,
    'percentage_active_secure_assets': 2,
    'total_threats': 3,
    'percentage_threaten_assets': 4,
};

const WorkspaceViewCard = (props) => {
    return (
        <>
            <div className={'flex flex-col md:flex-row gap-4'}>
                {Object.entries(props.statistics)
                    .filter(([key]) => Object.keys(statistics).includes(key))
                    .sort(([leftKey], [rightKey]) => statistics[leftKey] - statistics[rightKey])
                    .map(([key, value]) => <WorkspaceStatistic key={key} statKey={key} {...value} className={'w-full'}/>)}
            </div>
            <div className="grid gap-4 grid-cols-12 mt-4">
                {props.threats.length > 0 ? (
                    <>
                        <div className='col-span-12 md:col-span-4'>
                            <ThreatPieChart className={'h-80'} threats={props.threats}/>
                        </div>
                        <div className='col-span-12 md:col-span-8'>
                            <ThreatTable className={'h-80'} threats={props.threats}/>
                        </div>
                    </>
                ) : (
                    <Alert className={'mb-8 col-span-12'} type={'success'} title={'No threats detected'}
                           messages={['You\'ve done a good job securing your infrastructure. Everything\'s fine.']}
                           show={true}/>
                )}
                {props.changesCalendar !== undefined ? (
                    <div className={'col-span-12 ' + (props.threats.length > 0 ? 'md:col-span-6' : '')}>
                        <Calendar title={'Monthly changes'} calendar={props.changesCalendar}/>
                    </div>
                ) : null}
                {props.threats.length > 0 && props.threatsCalendar !== undefined ? (
                    <div className='col-span-12 md:col-span-6'>
                        <Calendar title={'Monthly threats'} calendar={props.threatsCalendar}/>
                    </div>
                ) : null}
                <div className='col-span-12'>
                    <AssetList className={'max-h-96'}
                               workspaceId={props.workspaceId} assets={props.assets}
                               assetFilters={props.assetListFilters}
                               setAssetFilters={props.updateAssetListFilters}
                               isLoading={props.isLoadingAssets}/>
                </div>
            </div>
        </>
    )
}

export default WorkspaceViewCard;
