import Table from "../Table/Table";
import WorkspaceTableItem from "./WorkspaceTableItem";
import {useState} from "react";

const WorkspaceTable = (props) => {
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const mappings = {
        'Name': 'name',
        'Refresh interval': 'refresh_interval',
        'Role': 'role',
        'Low threats': 'threats.low',
        'Medium threats': 'threats.medium',
        'High threats': 'threats.high',
    };

    const onClickTableHeader = (key) => {
        if (mappings[key] !== undefined) {
            setSortField(key);
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        }
    };

    const getItemValue = (item, key) => {
        if (!key.includes('.')) {
            return item[key];
        }

        // Handle sub-property
        const parts = key.split('.');
        return item[parts[0]][parts[1]] ?? 0;
    }

    const sortItems = (a, b) => {
        if (sortField === '') {
            return 0;
        }

        const sortFieldKey = mappings[sortField];

        const aValue = getItemValue(a, sortFieldKey);
        const bValue = getItemValue(b, sortFieldKey);

        return sortOrder === 'desc' ? (
            aValue < bValue ? -1 : (aValue > bValue) ? 1 : 0
        ) : (
            aValue > bValue ? -1 : (aValue < bValue) ? 1 : 0
        );
    }

    const workspaces = (props.workspaces ?? []).sort(sortItems).map((workspace) => {
        return {
            tableKey: workspace.id,
            ...workspace
        };
    });

    return (
        <Table className={props.className}
               keys={['Name', 'Refresh interval', 'Role', 'Low threats', 'Medium threats', 'High threats']}
               items={workspaces}
               tableItem={WorkspaceTableItem}
               onClickTableHeader={onClickTableHeader}
               sortField={sortField}
               sortOrder={sortOrder}/>
    )
}

export default WorkspaceTable;