import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import Page from "../../../components/Page/Page";
import PageHeader from "../../../components/Page/PageHeader";
import PageTitle from "../../../components/Page/PageTitle";
import {getWorkspace, getWorkspaceReports, handleDisconnect} from "../../../requests";
import AppContext from "../../../store/app-context";
import ReportTable from "../../../components/Report/ReportTable";

export default function WorkspacesReports() {
    const appContext = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();

    const [workspaceReports, setWorkspaceReports] = useState([]);

    const [workspace, setWorkspace] = useState({
        id: '',
        name: '',
        description: '',
        role: ''
    });

    const [pages, setPages] = useState([]);

    const onViewReport = (report) => {
        navigate('/workspaces/' + workspace.id + '/reports/' + report.id);
    }

    useEffect(() => {
        getWorkspace(appContext.token, params.workspace)
            .then((workspace) => {
                setWorkspace(workspace);

                setPages([
                    {id: 1, name: 'Workspaces', href: '/workspaces', current: false},
                    {id: 2, name: workspace.name, href: '/workspaces/' + workspace.id, current: false},
                    {id: 3, name: 'Reports', href: '/workspaces/' + workspace.id + '/reports', current: true},
                ]);
            })
            .catch((error) => handleDisconnect(error, appContext));

        getWorkspaceReports(appContext.token, params.workspace).then(setWorkspaceReports);

    }, [appContext, params.workspace, appContext.token]);

    return (
        <Page>
            <PageHeader pages={pages}>
                <PageTitle title={workspace.name + ' reports'}/>
            </PageHeader>
            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <ReportTable reports={workspaceReports} onViewReport={onViewReport}/>
                    </div>
                </div>
            </div>
        </Page>
    )
}
