import RecordTableItem from "./RecordTableItem";
import Table from "../Table/Table";

const RecordTable = (props) => {
    const records = (props.records ?? []).map((record) => {
        return {
            tableKey: record.id,
            ...record
        };
    });

    return (
        <Table className={props.className} title={'Records'} keys={['Name', 'Type', 'TTL', 'Values']}
               items={records} tableItem={RecordTableItem}/>
    )
}

export default RecordTable;