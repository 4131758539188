import HostTableItem from "./HostTableItem";
import Table from "../Table/Table";

const HostTable = (props) => {
    const hosts = (props.hosts ?? []).map((host) => {
        return {
            tableKey: host.id,
            ...host
        };
    });

    return (
        <Table className={props.className}
               title={'Hosts'}
               keys={['Address', 'AS', 'OS']}
               items={hosts}
               tableItem={HostTableItem}/>
    )
}

export default HostTable;