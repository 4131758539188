export const defaultAssetFilters = {
    'assetTypes': {
        'host': {
            'label': 'Hosts',
            'isEnabled': true,
        },
        'record': {
            'label': 'Records',
            'isEnabled': true,
        },
        'certificate': {
            'label': 'Certificates',
            'isEnabled': false,
        },
        'service': {
            'label': 'Services',
            'isEnabled': true,
        },
        'website': {
            'label': 'Websites',
            'isEnabled': true,
        },
        'domain': {
            'label': 'Domains',
            'isEnabled': true,
        },
        'autonomous_system': {
            'label': 'AS',
            'isEnabled': true,
        },
        'email': {
            'label': 'Email',
            'isEnabled': true,
        }
    },
    'assetStatuses': {
        'threatenOnly': {
            'label': 'Threaten only',
            'isEnabled': false,
        },
        'activeOnly': {
            'label': 'Active only',
            'isEnabled': true,
        },
    },
};

export const filterAssets = (assets, assetFilters) => {
    const shownAssetTypes = Object.entries(assetFilters.assetTypes).filter(([key, val]) => val['isEnabled']).map(([key, val]) => key);
    return assets.filter((node) => {
        return shownAssetTypes.includes(node.type) &&
            filterThreatenOnlyAsset(node, assetFilters.assetStatuses.threatenOnly.isEnabled) &&
            filterActiveOnlyAsset(node, assetFilters.assetStatuses.activeOnly.isEnabled) && filterAssetByTags(node, assetFilters.assetTags)
    });
}

const filterAssetByTags = (asset, tags) => {
    if (!tags) {
        return true;
    }

    const activeTags = Object.entries(tags)
        .filter(([key, value]) => value.isEnabled)
        .map(([key, value]) => parseInt(key));

    if (activeTags.length === 0) {
        return true;
    }

    const assetTagIds = asset.tags.map(tag => tag.id);
    return activeTags.every(tagId => assetTagIds.includes(tagId));
};

const filterThreatenOnlyAsset = (asset, threatenOnly) => {
    return !threatenOnly || asset.threats.length > 0;
}

const filterActiveOnlyAsset = (asset, activeOnly) => {
    return !activeOnly || asset.is_active;
}
