import React from 'react';
import {Menu} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";

function DropdownButton(props) {
    return (
        <div>
            <Menu.Button
                className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                {props.icon ?? ''}
                <span className="sr-only">{props.srOnly}</span>
                <span className={'hidden md:block md:ml-2'}>{props.title}</span>
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
            </Menu.Button>
        </div>
    );
}

export default DropdownButton;
