import React from 'react';

function InputSelect(props) {
    return (
        <select name={props.name} id={props.id}
               className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm " + (props.className ?? '')}
               placeholder={props.placeholder} value={props.value}
               onChange={props.onChange}>
            {props.children}
        </select>
    );
}

export default InputSelect;
