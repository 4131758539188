import Badge from "../../Badge";
import InputCheckbox from "../../UI/Inputs/InputCheckbox";

const AssetTableItem = (props) => {
    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <Badge title={props.type} classes={'bg-primary text-white'}/>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex flex-col">
                {props.name}
                <span className={'text-sm text-gray-400'}>{props.description}</span>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                <time dateTime={new Date(props.first_seen).toLocaleDateString('en-CA')}>
                    {new Date(props.first_seen).toLocaleDateString('en-CA')}
                </time>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                <time dateTime={new Date(props.last_seen).toLocaleDateString('en-CA')}>
                    {new Date(props.last_seen).toLocaleDateString('en-CA')}
                </time>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <InputCheckbox onChange={(e) => (props.isWorkspaceSecured ? null : props.onSelectAsset(props, e.target.checked))}
                               defaultChecked={props.is_secured ?? false} className={(props.isWorkspaceSecured ? '!text-gray-300' : '!text-primary')} />
            </td>
        </tr>
    )
}

export default AssetTableItem;
