import React from "react";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import AssetFilter from "../AssetFilter/AssetFilter";

const AssetMapFilters = (props) => {

    return (
        <Card>
            <CardBody>
                <div className={'flex gap-2'}>
                    <AssetFilter assetFilters={props.assetFilters}
                                 setAssetFilters={props.setAssetFilters}
                                 parentFilterKey={'assetTypes'}
                                 filterName={'Types'}/>
                    <AssetFilter assetFilters={props.assetFilters}
                                 setAssetFilters={props.setAssetFilters}
                                 parentFilterKey={'assetStatuses'}
                                 filterName={'Statuses'}/>
                </div>
            </CardBody>
        </Card>
    )
}

export default AssetMapFilters;
