import {useRef} from 'react'
import ReactJsonViewCompare from "react-json-view-compare";
import Modal from "../UI/Modal/Modal";

const RevisionModal = (props) => {
    const cancelButtonRef = useRef(null);

    return (
        <Modal isOpen={props.isOpen} onClose={props.setOpen} cancelButtonRef={cancelButtonRef}>
            <ReactJsonViewCompare oldData={props.oldData ?? {}} newData={props.newData ?? {}}/>
        </Modal>
    )
}

export default RevisionModal;
