import Table from "../Table/Table";
import TeamWorkspaceTableItem from "./TeamWorkspaceTableItem";

const TeamWorkspaceTable = (props) => {
    const workspaces = (props.workspaces ?? []).map((workspace) => {
        return {
            tableKey: workspace.id,
            ...workspace
        };
    });

    return (
        <Table className={props.className} title={'Workspaces'} keys={['Name', 'Refresh interval', 'Assets']}
               items={workspaces} tableItem={TeamWorkspaceTableItem}/>
    )
}

export default TeamWorkspaceTable;