import {Switch as HeadlessSwitch} from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Switch = (props) => {
    return (
        <HeadlessSwitch.Group as="div" className="flex items-center justify-around w-full">
            <span className="flex flex-grow flex-col mr-3">
                <HeadlessSwitch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                    {props.label}
                </HeadlessSwitch.Label>
            </span>
            <HeadlessSwitch
                checked={props.checked}
                onChange={props.setChecked}
                className={classNames(props.checked ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2')}>
                <span aria-hidden="true"
                      className={classNames(props.checked ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out')}/>
            </HeadlessSwitch>
        </HeadlessSwitch.Group>
    )
}

export default Switch;