import DomainTableItem from "./DomainTableItem";
import Table from "../Table/Table";

const DomainTable = (props) => {
    const domains = (props.domains ?? []).map((domain) => {
        return {
            tableKey: domain.id,
            ...domain
        };
    });

    return (
        <Table className={props.className} title={'Domains'} keys={['Name']}
               items={domains} tableItem={DomainTableItem}/>
    )
}

export default DomainTable;